import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate } from "react-router-dom";

import { Container, Form, Row, Col } from "react-bootstrap";
import { editProfileSchema } from "../../utils/validationSchema/validation";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProfileById, updateProfile } from "../../redux/slice/authSlice";
import { toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import UserLayout from "../../components/UserLayout/UserLayout";
import CityData from "../../utils/data/city.json";
import StateData from "../../utils/data/states.json";
import { firstCapitalLetter } from "../../utils/commonFunction";
import dayjs from "dayjs";
import InputField from "../../components/layout/shared/InputField";
import Spinner from "../../components/layout/shared/Spinner";
import { genderOptions } from "../../utils/data/staticData";
import { Control, colourStylesState } from "../../configs/stylesConfig";
import moment from "moment";

const defaultValues = {
  firstName: "",
  lastName: "",
  date_of_birth: new Date(),
  gender: "",
  country: "",
  mobile: "",
  email: "",
  terms: false,
  comments: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
};

const UsersProfile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const [loading, setLoading] = useState(false);

  const [selectedState, setSelectedState] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);

  const userId = loggedInUser?._id;

  useEffect(() => {
    if (userId) {
      dispatch(getProfileById(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (!currentUser) return;

    const data = {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      date_of_birth: moment(currentUser?.dob, "DD/MM/YYYY").format(
        "MM/DD/YYYY"
      ),
      gender: {
        label: firstCapitalLetter(currentUser?.gender),
        value: currentUser?.gender,
      },
      country: currentUser?.country,
      mobile: currentUser?.mobile,
      email: currentUser?.email,
      address1: currentUser?.address1,
      address2: currentUser?.address2,
      city: { label: currentUser?.city, value: currentUser?.city },
      state: { name: currentUser?.state, value: currentUser?.state },
      zipcode: currentUser?.zipcode,
      terms: currentUser?.terms,
    };

    if (currentUser?.state) {
      setSelectedState({ name: currentUser?.state, value: currentUser?.state });
    }

    reset({
      ...data,
    });
  }, [currentUser]);

  const {
    handleSubmit,
    setError,
    watch,
    register,
    handleBlur,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(editProfileSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);

    const postData = {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      dob: data?.date_of_birth
        ? moment(data?.date_of_birth, "MM/DD/YYYY").format("DD/MM/YYYY")
        : new Date(),
      gender: data?.gender?.value,
      country: data?.country,
      mobile: data?.mobile || "",
      email: data?.email,
      address1: data?.address1,
      address2: data?.address2,
      city: data?.city.value,
      state: data?.state?.name,
      zipcode: data?.zipcode,
      terms: data?.terms,
    };

    const params = { postData, userId: userId };

    const userData = await dispatch(updateProfile(params));

    if (!userData?.payload || userData?.payload === undefined) {
      setLoading(false);
      return;
    } else {
      setLoading(false);
      toast.success("Profile has been updated successfully");
      navigate("/user/dashboard");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedState) {
      const dataFromCity = CityData.filter((city) => {
        return city.state === selectedState.name;
      }).map((city) => {
        return {
          value: city.label,
          label: city.label,
        };
      });

      setCityOptions(dataFromCity);
      setIsCityDisabled(false); // Enable city dropdown when state is selected
    } else {
      setCityOptions([]);
      setIsCityDisabled(true); // Disable city dropdown when no state is selected
    }
  }, [selectedState]);

  return (
    <UserLayout>
      <section className="outer-dash-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="message-mail-box mt-3">
                <Container>
                  <Row className="align-items-center">
                    <Col md="12" className="m-auto">
                      <div className="">
                        <Row className="align-items-center">
                          <Col md={12}>
                            <div className="login-form">
                              <div className="d-flex align-items-center justify-content-between">
                                <h3 className="dash-common-heading">
                                  Profile Settings
                                </h3>
                              </div>
                              <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                  <Col md={6}>
                                    <Form.Group
                                      className="did-floating-label-content mb-3"
                                      controlId="firstName"
                                    >
                                      <Controller
                                        control={control}
                                        name="firstName"
                                        render={({ field }) => (
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-labe-astrisk"
                                            }
                                            type="text"
                                            name="firstName"
                                            label={"First Name"}
                                            value={field?.value}
                                            onChange={field?.onChange}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />

                                      {errors.firstName && (
                                        <Form.Text className="text-danger">
                                          {errors.firstName.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group
                                      className="did-floating-label-content mb-3"
                                      controlId="lastName"
                                    >
                                      <Controller
                                        control={control}
                                        name="lastName"
                                        render={({ field }) => (
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-labe-astrisk"
                                            }
                                            type="text"
                                            name="lastName"
                                            label={"Last Name"}
                                            value={field?.value}
                                            onChange={field?.onChange}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />

                                      {errors.lastName && (
                                        <Form.Text className="text-danger">
                                          {errors.lastName.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <Form.Group
                                      className="did-floating-label-content mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Controller
                                        control={control}
                                        name="email"
                                        render={({ field }) => (
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label did-floating-labe-astrisk"
                                            }
                                            type="email"
                                            disabled={true}
                                            name="email"
                                            label={"Email Address"}
                                            value={field?.value}
                                            onChange={field.onChange}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />

                                      {errors.email && (
                                        <Form.Text className="text-danger">
                                          {errors.email.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col md="6">
                                    <Form.Group
                                      className="mb-3 did-floating-label-content"
                                      controlId="mobile"
                                    >
                                      <Controller
                                        name="mobile"
                                        control={control}
                                        rules={{
                                          validate: (value) =>
                                            value === "" ||
                                            isValidPhoneNumber(value),
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <PhoneInput
                                            className={"did-floating-input"}
                                            label="Mobile"
                                            value={value}
                                            onChange={(e) => {
                                              onChange(e);
                                              setError("mobile", null);
                                            }}
                                            defaultCountry="US"
                                            id="mobile"
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />
                                      <label className="did-floating-label">
                                        Mobile
                                      </label>

                                      {errors.mobile && (
                                        <Form.Text className="text-danger">
                                          {errors.mobile.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <Form.Group
                                      className="mb-3 did-floating-label-content"
                                      controlId="address1"
                                    >
                                      <Controller
                                        control={control}
                                        name="address1"
                                        render={({ field }) => (
                                          <InputField
                                            className={"did-floating-input"}
                                            type={"text"}
                                            name="address1"
                                            label={"Address 1"}
                                            value={field?.value}
                                            onChange={field?.onChange}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />

                                      {errors.address1 && (
                                        <Form.Text className="text-danger">
                                          {errors.address1.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col md="6">
                                    <Form.Group
                                      className="did-floating-label-content mb-3"
                                      controlId="address2"
                                    >
                                      <Controller
                                        control={control}
                                        name="address2"
                                        render={({ field }) => (
                                          <InputField
                                            className={"did-floating-input"}
                                            type={"text"}
                                            name="address2"
                                            label={"Address 2"}
                                            value={field?.value}
                                            onChange={field?.onChange}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />

                                      {errors.address2 && (
                                        <Form.Text className="text-danger">
                                          {errors.address2.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <Form.Group
                                      className="mb-3 did-floating-label-content"
                                      controlId="date_of_birth"
                                    >
                                      <Controller
                                        control={control}
                                        name="date_of_birth"
                                        render={({ field }) => (
                                          <ReactDatePicker
                                            className="did-floating-input"
                                            dateFormat="MM/DD/YYYY"
                                            placeholderText={"MM/DD/YYYY"}
                                            onChange={(value) =>
                                              field.onChange(
                                                dayjs(value).format(
                                                  "MM/DD/YYYY"
                                                )
                                              )
                                            }
                                            value={field.value}
                                            defaultValues={null}
                                            maxDate={new Date()}
                                            isClearable={true}
                                            showYearDropdown // year show and scrolldown alos
                                            scrollableMonthYearDropdown={true}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />
                                      <label
                                        className={
                                          "did-floating-label-datepicker"
                                        }
                                      >
                                        Date of Birth
                                      </label>
                                      {errors.date_of_birth && (
                                        <Form.Text className="text-danger">
                                          {errors.date_of_birth.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group
                                      className="mb-3 did-floating-label-content"
                                      controlId="gender"
                                    >
                                      <Controller
                                        control={control}
                                        name="gender"
                                        render={({ field }) => (
                                          <Select
                                            className=""
                                            options={genderOptions}
                                            onChange={field.onChange}
                                            placeholder={""}
                                            value={field.value}
                                            styles={colourStylesState}
                                            isClearable={true}
                                            label={"Gender"}
                                            components={{
                                              Control: Control,
                                              DropdownIndicator: () => null,
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.gender && (
                                        <Form.Text className="text-danger">
                                          {errors.gender.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="state"
                                    >
                                      <Controller
                                        control={control}
                                        name="state"
                                        render={({ field }) => (
                                          <Select
                                            options={StateData}
                                            label={"State"}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            getOptionValue={(option) =>
                                              option.name
                                            }
                                            onChange={(value) => {
                                              field.onChange(value);
                                              setSelectedState(value);
                                              setValue("city", "");
                                            }}
                                            value={field.value}
                                            placeholder=" "
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={colourStylesState}
                                            components={{
                                              Control,
                                              DropdownIndicator: () => null,
                                              IndicatorSeparator: () => null,
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.state && (
                                        <Form.Text className="text-danger">
                                          {errors.state.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group
                                      className="mb-3 "
                                      controlId="city"
                                    >
                                      <Controller
                                        control={control}
                                        name="city"
                                        render={({ field }) => (
                                          <Select
                                            options={cityOptions}
                                            isDisabled={isCityDisabled}
                                            label={"City"}
                                            onChange={field.onChange}
                                            placeholder={" "}
                                            value={field.value}
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={colourStylesState}
                                            components={{
                                              Control,
                                              DropdownIndicator: () => null,
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.city && (
                                        <Form.Text className="text-danger">
                                          {errors.city.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={6}>
                                    <Form.Group
                                      className="did-floating-label-content mb-3"
                                      controlId="country"
                                    >
                                      <Controller
                                        control={control}
                                        name="country"
                                        render={({ field }) => (
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label"
                                            }
                                            type="text"
                                            name="country"
                                            label={"Country"}
                                            value={"USA"}
                                            disabled={true}
                                            onChange={field?.onChange}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />

                                      {errors.country && (
                                        <Form.Text className="text-danger">
                                          {errors.country.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group
                                      className="did-floating-label-content mb-3"
                                      controlId="zipcode"
                                    >
                                      <Controller
                                        control={control}
                                        name="zipcode"
                                        render={({ field }) => (
                                          <InputField
                                            className={"did-floating-input"}
                                            classNameLabel={
                                              "did-floating-label"
                                            }
                                            type="number"
                                            name="zipcode"
                                            label={"Zipcode"}
                                            value={field?.value}
                                            onChange={field?.onChange}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      />

                                      {errors.zipcode && (
                                        <Form.Text className="text-danger">
                                          {errors.zipcode.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <Form.Group controlId="policycheck">
                                      <Form.Check
                                        type="checkbox"
                                        label="I agree to privacy policy & terms"
                                        {...register("terms", {
                                          required:
                                            "You must accept the privacy policy & terms",
                                        })}
                                      />
                                      {errors.terms && (
                                        <Form.Text className="text-danger">
                                          {errors.terms.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <div>
                                  <button className="login-btn w-100  mt-2">
                                    Update Profile
                                  </button>
                                </div>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="space-bottom">.</div>
      </section>
      <Spinner isLoading={loading} />
    </UserLayout>
  );
};

export default UsersProfile;
