import React, { useState, useEffect, useRef } from "react";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/layout/shared/Spinner";
import { Box, Container, Tooltip, Typography } from "@mui/material";
import ModalComponent from "../../components/layout/shared/ModalComponent";
import ModalWrapper from "../../components/layout/shared/ModalWrapper";
import { Button } from "react-bootstrap";
import SuperAdminEditUser from "./SuperAdminEditUser";
import { firstCapitalLetter } from "../../utils/commonFunction";
import SuperAdminInviteUserForm from "./SuperAdminInviteUserForm";
import SuperAdminImportUsersForm from "./SuperAdminImportUsersForm";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import SuperAdminAddUser from "./SuperAdminAddUser";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import { toggleUserActive } from "../../redux/slice/authSlice";
import { getAllUsers } from "../../redux/slice/userSlice";
import { REACT_APP_BACKEND_URL } from "../../configs/config";
import { POSTDELETEUSER, POSTREINVITEUSER } from "../../services/userService";
import { toast } from "react-toastify";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../configs/config";

const UserListForSuperAdmin = () => {
  const [loading, setLoading] = useState(false);
  const userLoading = useSelector(({ user }) => user.loading);
  const dispatch = useDispatch();
  const reportLoading = useSelector(({ report }) => report.loading);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllUsers())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const userToggleHandler = async (user) => {
    setLoading(true);
    const params = {
      id: user?._id,
      active: !user?.active,
    };

    await dispatch(toggleUserActive(params))
      .then((res) => {
        dispatch(getAllUsers());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReinviteUserModal, setShowReInviteUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false);
  const [userId, setUserId] = useState("");
  const userListFromReducer = useSelector(({ user }) => user.userList);
  const userListRef = useRef();
  userListRef.current = userListFromReducer?.length && userListFromReducer;

  const downloadFileWithAuthorization = async (fileUrl, accessToken) => {
    setIsExportButtonDisabled(true);
    try {
      const headers = {
        Authorization: `${accessToken}`,
        // Add any other headers if required
      };

      const response = await fetch(fileUrl, {
        method: "GET",
        headers,
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "List of Users"; // Specify the filename for the downloaded file

        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to download the file.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsExportButtonDisabled(false);
    }
  };

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleAddUserModalShow = () => {
    setShowAddUserModal(true);
  };

  const handleAddUserModalClose = () => {
    setShowAddUserModal(false);
  };

  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleInviteModalShow = () => {
    setShowInviteModal(true);
  };

  const handleInviteModalClose = () => {
    setShowInviteModal(false);
  };

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const handleFileUploadModalShow = () => {
    setShowFileUploadModal(true);
  };

  const handleFileUploadModalClose = () => {
    setShowFileUploadModal(false);
  };

  const deleteUserHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const deletedResponse = await POSTDELETEUSER({
        id: userId,
        isDeleted: true,
      });

      if (deletedResponse?.data?.isDeleted) {
        success = true;
        dispatch(getAllUsers());
      }
    } catch (error) {
      // Handle the error here, for example, log the error message or show a notification to the user.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);

      if (success) {
        toast.success("User has been removed successfully");
      }
    }
  };
  const resendInviteHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const inviteResponse = await POSTREINVITEUSER({ id: userId });

      if (
        inviteResponse?.data?.accepted?.length > 0 &&
        inviteResponse?.status === 200
      ) {
        success = true;
        dispatch(getAllUsers());
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowReInviteUserModal(false);

      if (success) {
        toast.success("Invitation mail sent successfully!");
      }
    }
  };

  const handleEditUserModalShow = (userId) => {
    setUserId(userId);
    setShowEditUserModal(true);
  };

  const handleEditUserModalClose = (userId) => {
    setShowEditUserModal(false);
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (stId) => {
    setShowDeleteModal(false);
  };

  const rowReInviteHandler = (user_id) => {
    setUserId(user_id);
    handleReinviteModalShow(user_id);
  };

  const handleReinviteModalShow = () => {
    setShowReInviteUserModal(true);
  };

  const handleReinviteModalClose = (stId) => {
    setShowReInviteUserModal(false);
  };

  const rowDeleteHandler = (user_id) => {
    setUserId(user_id);
    handleDeleteModalShow(user_id);
  };

  const rowEditHandler = (userId) => {
    handleEditUserModalShow(userId);
  };

  const downloadExcel = async () => {
    setIsExportButtonDisabled(true);

    const encryptedToken = localStorage.getItem("accessToken");
    const token =
      encryptedToken &&
      CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
        REACT_APP_CRYPTOJS_VECTOR,
      }).toString(CryptoJS.enc.Utf8);

    downloadFileWithAuthorization(
      `${REACT_APP_BACKEND_URL}/auth/exportUsers`,
      token
    );
    setIsExportButtonDisabled(false);
  };

  /* Material react table */

  const columns = [
    {
      accessorKey: "fullName",
      header: "Full Name",

      grow: false,
      Cell: ({ row }) => {
        const user = row.original;
        const displayValue =
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : "-N/A-";
        return (
          <Tooltip title={displayValue}>
            <span>{displayValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",

      grow: false,
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue() || "-N/A-"}>
          <span>{cell.getValue() || "-N/A-"}</span>
        </Tooltip>
      ),
    },
    {
      accessorKey: "state",
      header: "State",

      grow: false,
      Cell: ({ cell }) => {
        const stateValue = cell.getValue()
          ? firstCapitalLetter(cell.getValue())
          : "-N/A-";
        return (
          <Tooltip title={stateValue}>
            <span>{stateValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "gender",
      header: "Gender",
      grow: false,
      Cell: ({ cell }) => {
        const genderValue = cell.getValue()
          ? firstCapitalLetter(cell.getValue())
          : "-N/A-";
        return (
          <Tooltip title={genderValue}>
            <span>{genderValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "company.company_name",
      header: "Company Name",

      grow: false,
      Cell: ({ row }) => {
        const companyName = row.original.company?.company_name || "-N/A-";
        return (
          <Tooltip title={companyName}>
            <span>{companyName}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "job_title",
      header: "Job Title",

      grow: false,
      Cell: ({ cell }) => {
        const jobTitle = cell.getValue() || "-N/A-";
        return (
          <Tooltip title={jobTitle}>
            <span>{jobTitle}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "role",
      header: "Role",

      grow: false,
      Cell: ({ row }) => {
        const roleValue = row.original.role?.[0] || "-N/A-";
        return (
          <Tooltip title={roleValue}>
            <span>{roleValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => {
        const user = row.original;
        return (
          <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
            {/* Toggle User Activation */}
            <Tooltip title={user?.active ? "Disable User" : "Enable User"}>
              <div className="toggle-btn">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={user?.active}
                    onChange={() => {
                      userToggleHandler(user);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </Tooltip>

            {/* Remove User Button */}
            <Tooltip title="Remove User">
              <Button
                style={{ backgroundColor: "transparent" }}
                onClick={() => rowDeleteHandler(user?._id)}
              >
                <DeleteIcon style={{ color: "#3a49a1" }} />
              </Button>
            </Tooltip>

            {/* Edit User Detail Button */}
            <Tooltip title="Edit User Detail">
              <Button
                style={{ backgroundColor: "transparent" }}
                onClick={() => rowEditHandler(user?._id)}
              >
                <EditIcon style={{ color: "#3a49a1" }} />
              </Button>
            </Tooltip>

            {/* Resend Invitation Button */}
            {user?.invite_url && (
              <Tooltip placement="left-start" title="Resend Invitation">
                <Button
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => rowReInviteHandler(user?._id)}
                >
                  <EmailIcon style={{ color: "#3a49a1" }} />
                </Button>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: userListFromReducer || [],
    enableDensityToggle: false,
    enableSorting: false,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: false,
    enableHiding: false,
    initialState: {
      density: "compact",
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 16.2rem)",
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #3a4981",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        "& .Mui-TableHeadCell-Content-Labels": {
          padding: "0px",
        },
        "& .MuiBox-root": {
          padding: "0px",
        },
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
      customizeToolbar: {
        height: "50px",
      },
    },

    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#F6f6f6",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "rgba(65, 213, 213, 0.1)",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          padding: "0.5rem",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Typography
            variant="h6"
            style={{ color: "darkblue", fontWeight: "bold" }}
          >
            Users
          </Typography>
          <MRT_GlobalFilterTextField table={table} />
        </Box>

        <div
          className="d-md-flex"
          style={{
            justifyContent: "space-between",
          }}
        >
          <div md={6} className="new-message-btn d-flex gap-1">
            <Tooltip title={"Invite Users"}>
              <Button className="new-message" onClick={handleInviteModalShow}>
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </Button>
            </Tooltip>
            <Tooltip title={"Import Users"}>
              <Button
                className="new-message"
                onClick={handleFileUploadModalShow}
              >
                <i className="fa fa-upload" aria-hidden="true"></i>
              </Button>
            </Tooltip>
            <Tooltip title={"Export Users"}>
              <Button
                className="new-message"
                onClick={downloadExcel}
                disabled={isExportButtonDisabled}
              >
                <i className="fa fa-download" aria-hidden="true"></i>
              </Button>
            </Tooltip>

            <Tooltip title={"Add User"}>
              <Button className="new-message" onClick={handleAddUserModalShow}>
                <i className="fa fa-user-plus" aria-hidden="true"></i>
              </Button>
            </Tooltip>
          </div>
        </div>
      </Box>
    ),
  });

  return (
    <>
      <Container className="p-0 " style={{ maxWidth: "100%" }}>
        <MaterialReactTable table={table} />
      </Container>

      <ModalComponent
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-trash" aria-hidden="true"></i>
          <h4>Remove</h4>
          <p>Are you sure you want to Remove this User ?</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button className="red-back" onClick={deleteUserHandler}>
              Remove
            </Button>
          </div>
        </div>
      </ModalComponent>
      <ModalWrapper
        keyboard={true}
        showModal={showEditUserModal}
        handleClose={handleEditUserModalClose}
        title={"Update User"}
        size="lg"
      >
        <SuperAdminEditUser
          userId={userId}
          handleEditUserModalClose={handleEditUserModalClose}
          handlePageAfterEdit={() => {}}
        />
      </ModalWrapper>

      <ModalWrapper
        showModal={showAddUserModal}
        handleClose={handleAddUserModalClose}
        title="Add User"
        keyboard={true}
        size="lg"
      >
        <SuperAdminAddUser
          handleAddUserModalClose={handleAddUserModalClose}
          handlePageAfterEdit={() => {}}
        />
      </ModalWrapper>
      <ModalWrapper
        showModal={showInviteModal}
        handleClose={handleInviteModalClose}
        title="Invite User"
        keyboard={true}
        size="md"
      >
        <SuperAdminInviteUserForm
          handleInviteModalClose={handleInviteModalClose}
          handlePageAfterEdit={() => {}}
        />
      </ModalWrapper>
      <ModalWrapper
        showModal={showFileUploadModal}
        handleClose={handleFileUploadModalClose}
        title="Import Users"
        keyboard={true}
        size="lg"
      >
        <SuperAdminImportUsersForm
          handleFileUploadModalClose={handleFileUploadModalClose}
          handlePageAfterEdit={() => {}}
        />
      </ModalWrapper>

      <ModalComponent
        show={showReinviteUserModal}
        handleClose={handleReinviteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-envelope" aria-hidden="true"></i>
          <h4>Resend Invitation</h4>
          <p>Click the button below to resend the invitation!</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleReinviteModalClose}>
              Cancel
            </Button>
            <Button className="blue-back" onClick={resendInviteHandler}>
              Send
            </Button>
          </div>
        </div>
      </ModalComponent>

      <Spinner isLoading={loading || userLoading || reportLoading} />
    </>
  );
};

export default UserListForSuperAdmin;
