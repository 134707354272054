import React from "react";

const InputField = ({ value, label, name, disabled, readOnly, type, onChange, className, classNameLabel, min, max, maxLength, minLength, required }) => (
  <div className="did-floating-label-content">
    <input
      className={className}
      disabled={disabled}
      readOnly={readOnly}
      placeholder=" "
      type={type}
      value={value}
      name={name}
      min={min || ''}
      max={max || ''}
      maxLength={maxLength || ''}
      minLength={minLength || 0}
      onChange={onChange}
      autoComplete="new-password"
      required={required || false}
    />
    {label && <label className={classNameLabel ? classNameLabel : "did-floating-label"} >{label}</label>}
  </div>
);

export default InputField;