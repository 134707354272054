import React, { useState, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { useForm, Controller } from "react-hook-form";
import CryptoJS from "crypto-js";

import Select from "react-select";

import { yupResolver } from "@hookform/resolvers/yup";

import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Container, Form, Row, Col, Navbar } from "react-bootstrap";
import { userOnboardSchema } from "../../utils/validationSchema/validation";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { userRegister } from "../../redux/slice/authSlice";
import dayjs from "dayjs";
import { genderOptions } from "../../utils/data/staticData";
import CityData from "../../utils/data/city.json";
import StateData from "../../utils/data/states.json";
import { getUserById } from "../../redux/slice/userSlice";
import { firstCapitalLetter } from "../../utils/commonFunction";
import InputField from "../../components/layout/shared/InputField";
import { toast } from "react-toastify";
import Spinner from "../../components/layout/shared/Spinner";
import { Control, colourStylesState } from "../../configs/stylesConfig";
import moment from "moment";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../configs/config";

const defaultValues = {
  firstName: "",
  lastName: "",
  date_of_birth: new Date(),
  gender: "",
  mobile: "",
  state: "",
  city: "",
  address1: "",
  address2: "",
  zipcode: "",
  new_password: "",
  confirm_password: "",
  terms: false,
};

const UserOnboard = () => {
  const navigate = useNavigate();
  // ** States

  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);

  const currentUser = useSelector(({ user }) => user.user);

  const toggleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmNewPassword((prev) => !prev);
  };

  const {
    handleSubmit,
    register,
    handleBlur,
    control,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(userOnboardSchema),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(
      window.location.search.replace(/&amp;/g, "&")
    );

    const urlUserId = urlParams.get("userid");
    const userToken = urlParams.get("token");

    if (urlUserId === undefined || userToken === undefined) {
      toast.error("This link is invalid");
      navigate("/", { replace: true });
    } else {
      // Set token in local storage
      const encryptedToken = CryptoJS.AES.encrypt(
        userToken,
        REACT_APP_CRYPTOJS_SECRETKEY,
        {
          REACT_APP_CRYPTOJS_VECTOR,
        }
      ).toString();
      localStorage.setItem("accessToken", encryptedToken);

      // Set userId using useState
      setUserId(urlUserId);

      // Make API request
      dispatch(getUserById(urlUserId))
        .then((res) => {
          if (res?.error?.message === "Rejected") {
            localStorage.removeItem("accessToken");
            toast.error("This link is expired");
            navigate("/", { replace: true });
          }
        })
        .catch((error) => {
          console.error("Error in getUserById:", error);
          localStorage.removeItem("accessToken");
          navigate("/", { replace: true });
        });
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (selectedState) {
      const dataFromCity = CityData.filter((city) => {
        return city.state === selectedState.name;
      }).map((city) => {
        return {
          value: city.label,
          label: city.label,
        };
      });

      setCityOptions(dataFromCity);
      setIsCityDisabled(false); // Enable city dropdown when state is selected
    } else {
      setCityOptions([]);
      setIsCityDisabled(true); // Disable city dropdown when no state is selected
    }
  }, [selectedState]);

  useEffect(() => {
    if (!currentUser) return;

    const data = {
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      mobile: currentUser?.mobile || "",

      date_of_birth: currentUser?.dob
        ? moment(currentUser?.dob, "DD/MM/YYYY").format("MM/DD/YYYY")
        : new Date(),
      gender: {
        label: firstCapitalLetter(currentUser?.gender) || "",
        value: currentUser?.gender || "",
      },
      address1: currentUser?.address1,
      address2: currentUser?.address2,
      city: { label: currentUser?.city || "", value: currentUser?.city || "" },
      state: {
        name: currentUser?.state || "",
        value: currentUser?.state || "",
      },
      country: currentUser?.country,
      zipcode: currentUser?.zipcode,

      terms: currentUser?.terms,
    };

    setSelectedState({ name: currentUser?.state, value: currentUser?.state });

    reset({
      ...data,
    });
  }, [currentUser]);

  // if (!userId) {
  //   return <Navigate to="/" state={{ from: location }} replace />;
  // }

  const onSubmit = async (data) => {
    setLoading(true);

    const params = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      dob: data?.date_of_birth
        ? moment(data?.date_of_birth, "MM/DD/YYYY").format("DD/MM/YYYY")
        : new Date(),
      id: userId,
      state: data?.state?.name,
      mobile: data?.mobile,
      gender: data?.gender?.value,
      address1: data?.address1,
      address2: data?.address2,
      city: data?.city?.value,
      role: data?.role,
      zipcode: data?.zipcode,
      password: data?.confirm_password,
    };

    try {
      const signupData = await dispatch(userRegister(params));

      if (!signupData?.payload || signupData?.payload === undefined) {
        setLoading(false);
        return;
      } else {
        setLoading(false);
        toast.success(
          "Onboarding done successfully, Please login with your new credentials"
        );
        navigate(`/login`);
      }
    } catch (error) {
      // Handle the error here, you can show an error message or log it.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      localStorage.removeItem("accessToken");
    }
  };

  return (
    <section className="outer-dash-section">
      <header
        className="main-header login-header-home"
        style={{ backgroundColor: "#3a4981" }}
      >
        <Navbar expand="lg" sticky="top">
          <Container fluid>
            <Row className="w-100 m-auto">
              <Col>
                <div className="d-flex align-items-center">
                  <Navbar.Brand className="d-block p-0">
                    <Link to="/login">
                      <img
                        src={"/assets/images/white_logo.png"}
                        style={{ maxHeight: "50px" }}
                        alt="logo"
                        className="img-fluid"
                      />
                    </Link>
                  </Navbar.Brand>
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
      <Container>
        <Row>
          <Col lg={12}>
            <div
              className="login-section onboard"
              style={{ minHeight: "auto", height: "auto", marginTop: "70px" }}
            >
              <Container>
                <Row className="align-items-center">
                  <Col md="12" className="m-auto">
                    <div className="shadow-box">
                      <Row className="align-items-center">
                        <Col md={12}>
                          <div className="login-form">
                            <h3>Welcome to Avalon</h3>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              <Row>
                                <Col md={4}>
                                  <Form.Group
                                    className="did-floating-label-content mb-3"
                                    controlId="firstName"
                                  >
                                    <Controller
                                      control={control}
                                      name="firstName"
                                      render={({ field }) => (
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-labe-astrisk"
                                          }
                                          type="text"
                                          name="firstName"
                                          label={"First Name"}
                                          value={field?.value}
                                          onChange={field?.onChange}
                                          onBlur={handleBlur}
                                        />
                                      )}
                                    />

                                    {errors.firstName && (
                                      <Form.Text className="text-danger">
                                        {errors.firstName.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group
                                    className="did-floating-label-content mb-3"
                                    controlId="lastName"
                                  >
                                    <Controller
                                      control={control}
                                      name="lastName"
                                      render={({ field }) => (
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={
                                            "did-floating-label did-floating-labe-astrisk"
                                          }
                                          type="text"
                                          name="lastName"
                                          label={"Last Name"}
                                          value={field?.value}
                                          onChange={field?.onChange}
                                          onBlur={handleBlur}
                                        />
                                      )}
                                    />

                                    {errors.lastName && (
                                      <Form.Text className="text-danger">
                                        {errors.lastName.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col md="4">
                                  <Form.Group
                                    className="mb-3 did-floating-label-content"
                                    controlId="date_of_birth"
                                  >
                                    <Controller
                                      control={control}
                                      name="date_of_birth"
                                      render={({ field }) => (
                                        <ReactDatePicker
                                          className="did-floating-input"
                                          dateFormat="MM/DD/YYYY"
                                          placeholderText={"MM/DD/YYYY"}
                                          onChange={(value) =>
                                            field.onChange(
                                              dayjs(value).format("MM/DD/YYYY")
                                            )
                                          }
                                          value={field.value}
                                          defaultValues={null}
                                          maxDate={new Date()}
                                          isClearable={true}
                                          showYearDropdown // year show and scrolldown alos
                                          scrollableMonthYearDropdown={true}
                                          onBlur={handleBlur}
                                        />
                                      )}
                                    />
                                    <label
                                      className={
                                        "did-floating-label-datepicker"
                                      }
                                    >
                                      Date of Birth
                                    </label>
                                    {errors.date_of_birth && (
                                      <Form.Text className="text-danger">
                                        {errors.date_of_birth.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col md={4}>
                                  <Form.Group
                                    className="mb-3 did-floating-label-content"
                                    controlId="gender"
                                  >
                                    <Controller
                                      control={control}
                                      name="gender"
                                      render={({ field }) => (
                                        <Select
                                          className=""
                                          options={genderOptions}
                                          onChange={field.onChange}
                                          placeholder={""}
                                          value={field.value}
                                          styles={colourStylesState}
                                          isClearable={true}
                                          label={"Gender"}
                                          components={{
                                            Control: Control,
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                          }}
                                        />
                                      )}
                                    />

                                    {errors.gender && (
                                      <Form.Text className="text-danger">
                                        {errors.gender.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group
                                    className="mb-3 did-floating-label-content"
                                    controlId="address1"
                                  >
                                    <Controller
                                      control={control}
                                      name="address1"
                                      render={({ field }) => (
                                        <InputField
                                          className={"did-floating-input"}
                                          type={"text"}
                                          name="address1"
                                          label={"Address 1"}
                                          value={field?.value}
                                          onChange={field?.onChange}
                                          onBlur={handleBlur}
                                        />
                                      )}
                                    />

                                    {errors.address1 && (
                                      <Form.Text className="text-danger">
                                        {errors.address1.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group
                                    className="did-floating-label-content mb-3"
                                    controlId="address2"
                                  >
                                    <Controller
                                      control={control}
                                      name="address2"
                                      render={({ field }) => (
                                        <InputField
                                          className={"did-floating-input"}
                                          type={"text"}
                                          name="address2"
                                          label={"Address 2"}
                                          value={field?.value}
                                          onChange={field?.onChange}
                                          onBlur={handleBlur}
                                        />
                                      )}
                                    />

                                    {errors.address2 && (
                                      <Form.Text className="text-danger">
                                        {errors.address2.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md="4">
                                  <Form.Group
                                    className="mb-3 did-floating-label-content"
                                    controlId="mobile"
                                  >
                                    <Controller
                                      name="mobile"
                                      control={control}
                                      rules={{
                                        validate: (value) =>
                                          value === "" ||
                                          isValidPhoneNumber(value),
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <PhoneInput
                                          className={"did-floating-input"}
                                          label="Mobile"
                                          value={value}
                                          onChange={(e) => {
                                            onChange(e);
                                            setError("mobile", null);
                                          }}
                                          defaultCountry="US"
                                          id="mobile"
                                          onBlur={handleBlur}
                                        />
                                      )}
                                    />
                                    <label className="did-floating-label">
                                      Mobile
                                    </label>

                                    {errors.mobile && (
                                      <Form.Text className="text-danger">
                                        {errors.mobile.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col md={4}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="state"
                                  >
                                    <Controller
                                      control={control}
                                      name="state"
                                      render={({ field }) => (
                                        <Select
                                          options={StateData}
                                          label={"State"}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.name
                                          }
                                          onChange={(value) => {
                                            field.onChange(value);
                                            setSelectedState(value);
                                            setValue("city", "");
                                          }}
                                          value={field.value}
                                          placeholder=" "
                                          isSearchable={true}
                                          isClearable={true}
                                          styles={colourStylesState}
                                          components={{
                                            Control,
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                          }}
                                        />
                                      )}
                                    />

                                    {errors.state && (
                                      <Form.Text className="text-danger">
                                        {errors.state.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md="4">
                                  <Form.Group
                                    className="mb-3 "
                                    controlId="city"
                                  >
                                    <Controller
                                      control={control}
                                      name="city"
                                      render={({ field }) => (
                                        <Select
                                          options={cityOptions}
                                          isDisabled={isCityDisabled}
                                          label={"City"}
                                          onChange={field.onChange}
                                          placeholder={" "}
                                          value={field.value}
                                          isSearchable={true}
                                          isClearable={true}
                                          styles={colourStylesState}
                                          components={{
                                            Control,
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                          }}
                                        />
                                      )}
                                    />

                                    {errors.city && (
                                      <Form.Text className="text-danger">
                                        {errors.city.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md="4">
                                  <Form.Group
                                    className="did-floating-label-content mb-3"
                                    controlId="zipcode"
                                  >
                                    <Controller
                                      control={control}
                                      name="zipcode"
                                      render={({ field }) => (
                                        <InputField
                                          className={"did-floating-input"}
                                          classNameLabel={"did-floating-label"}
                                          type="number"
                                          name="zipcode"
                                          label={"Zipcode"}
                                          value={field?.value}
                                          onChange={field?.onChange}
                                          onBlur={handleBlur}
                                        />
                                      )}
                                    />

                                    {errors.zipcode && (
                                      <Form.Text className="text-danger">
                                        {errors.zipcode.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group
                                    className="did-floating-label-content mb-3"
                                    controlId="new_password"
                                  >
                                    <Controller
                                      control={control}
                                      name="new_password"
                                      render={({ field }) => (
                                        <input
                                          className="did-floating-input"
                                          placeholder=" "
                                          type={
                                            showNewPassword
                                              ? "text"
                                              : "password"
                                          }
                                          value={field?.value}
                                          onChange={field?.onChange}
                                        />
                                      )}
                                    />
                                    <label className="did-floating-label did-floating-labe-astrisk">
                                      New Password
                                    </label>
                                    {showNewPassword ? (
                                      <span
                                        onClick={toggleShowNewPassword}
                                        id="#password-field"
                                        style={{
                                          color: "#24a5a5",
                                          float: "right",
                                          marginLeft: "-25px",
                                          marginTop: "8px",
                                          position: "relative",
                                          right: "10px",
                                          zIndex: "2",
                                          cursor: "pointer",
                                        }}
                                        className="fa fa-fw fa-eye field-icon toggle-password"
                                      ></span>
                                    ) : (
                                      <span
                                        onClick={toggleShowNewPassword}
                                        style={{
                                          color: "#24a5a5",
                                          float: "right",
                                          marginLeft: "-25px",
                                          marginTop: "8px",
                                          position: "relative",
                                          right: "10px",
                                          cursor: "pointer",
                                          zIndex: "2",
                                        }}
                                        id="#password-field"
                                        className="fa fa-fw fa-eye-slash field-icon toggle-password"
                                      ></span>
                                    )}
                                    {errors.new_password && (
                                      <Form.Text className="text-danger">
                                        {errors.new_password.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group
                                    className="did-floating-label-content mb-3"
                                    controlId="confirm_password"
                                  >
                                    <Controller
                                      control={control}
                                      name="confirm_password"
                                      render={({ field }) => (
                                        <input
                                          className="did-floating-input"
                                          placeholder=" "
                                          type={
                                            showConfirmNewPassword
                                              ? "text"
                                              : "password"
                                          }
                                          value={field?.value}
                                          onChange={field?.onChange}
                                        />
                                      )}
                                    />
                                    <label className="did-floating-label did-floating-labe-astrisk">
                                      Confirm Password
                                    </label>
                                    {showConfirmNewPassword ? (
                                      <span
                                        onClick={toggleShowConfirmPassword}
                                        id="#password-field"
                                        style={{
                                          color: "#24a5a5",
                                          float: "right",
                                          marginLeft: "-25px",
                                          marginTop: "8px",
                                          position: "relative",
                                          right: "10px",
                                          zIndex: "2",
                                          cursor: "pointer",
                                        }}
                                        className="fa fa-fw fa-eye field-icon toggle-password"
                                      ></span>
                                    ) : (
                                      <span
                                        onClick={toggleShowConfirmPassword}
                                        style={{
                                          color: "#24a5a5",
                                          float: "right",
                                          marginLeft: "-25px",
                                          marginTop: "8px",
                                          position: "relative",
                                          right: "10px",
                                          zIndex: "2",
                                          cursor: "pointer",
                                        }}
                                        id="#password-field"
                                        className="fa fa-fw fa-eye-slash field-icon toggle-password"
                                      ></span>
                                    )}
                                    {errors.confirm_password && (
                                      <Form.Text className="text-danger">
                                        {errors.confirm_password.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>

                                <Col md={12}>
                                  <Form.Group controlId="policycheck">
                                    <Form.Check
                                      type="checkbox"
                                      label="I agree to privacy policy & terms"
                                      {...register("terms", {
                                        required:
                                          "You must accept the privacy policy & terms",
                                      })}
                                    />
                                    {errors.terms && (
                                      <Form.Text className="text-danger">
                                        {errors.terms.message}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="justify-content-center">
                                <Col md="12">
                                  <div>
                                    <button
                                      className={`login-btn w-100 m-0 ${
                                        loading ? "disabled" : ""
                                      }`}
                                      type="submit" // Change to type="submit" to trigger form submission
                                      disabled={loading} // Disable the button while loading
                                    >
                                      Save and continue to login
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading} />
    </section>
  );
};

export default UserOnboard;
