import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import "react-phone-number-input/style.css";

import { Container, Form, Row, Col } from "react-bootstrap";
import {
  addUpnSchema,
  editUpnSchema,
} from "../../utils/validationSchema/validation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { toast } from "react-toastify";
import {
  editUpnByUpnId,
  getUpnById,
  getUpnNamesByCompanyId,
} from "../../redux/slice/upnSlice";
import Spinner from "../../components/layout/shared/Spinner";
import InputField from "../../components/layout/shared/InputField";

const defaultValues = {
  user_principal: "",
};

const SuperAdminEditUpn = (props) => {
  // ** States

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    handleBlur,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(editUpnSchema),
  });

  const currentUpn = useSelector(({ upn }) => upn.userPrincipal);

  const { upnId } = props;

  useEffect(() => {
    if (upnId) {
      dispatch(getUpnById(upnId));
    }
  }, [dispatch, upnId]);

  useEffect(() => {
    if (!currentUpn) return;

    const data = {
      user_principal: currentUpn?.user_principal_name,
    };

    reset({
      ...data,
    });
  }, [currentUpn]);

  const onSubmit = async (data) => {
    setLoading(true);
    const params = {
      user_principal_name: data?.user_principal,
      company_id: props?.company?.value,
      id: currentUpn?._id,
    };

    try {
      const upnData = await dispatch(editUpnByUpnId(params));

      if (!upnData?.payload || upnData?.payload === undefined) {
        setLoading(false);
        return;
      } else {
        dispatch(getUpnNamesByCompanyId(props?.company?.id));
        props?.handleEditUpnModalClose();
        toast.success("UPN has been updated successfully");
      }
    } catch (error) {
      // Handle the error here, for example, log the error or display an error message.
      console.error("An error occurred:", error);
    } finally {
      // This block will always be executed, whether there was an error or not.
      setLoading(false);
    }
  };

  return (
    <section className="">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Container>
              <Row className="align-items-center">
                <Col md="12" className="m-auto">
                  <div className="shadow-box p-0">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="login-form mt-0">
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="name"
                                >
                                  <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="name"
                                        label={"Company Name"}
                                        value={props?.company?.name}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                      />
                                    )}
                                  />

                                  {errors.name && (
                                    <Form.Text className="text-danger">
                                      {errors.name.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="user_principal"
                                >
                                  <Controller
                                    control={control}
                                    name="user_principal"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="user_principal"
                                        label={"Roles"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.user_principal && (
                                    <Form.Text className="text-danger">
                                      {errors.user_principal.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Form.Group controlId="policycheck">
                                  <Form.Check
                                    type="checkbox"
                                    label="I agree to privacy policy & terms"
                                    {...register("terms", {
                                      required:
                                        "You must accept the privacy policy & terms",
                                    })}
                                  />
                                  {errors.terms && (
                                    <Form.Text className="text-danger">
                                      {errors.terms.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <div>
                              <button
                                className={`login-btn w-100 m-0 ${
                                  loading ? "disabled" : ""
                                }`}
                                type="submit" // Change to type="submit" to trigger form submission
                                disabled={loading} // Disable the button while loading
                              >
                                Update UPN
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading} />
    </section>
  );
};

export default SuperAdminEditUpn;
