/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GETALLCOMPANIES,
  GETCOMPANYBYID,
  POSTADDCOMPANY,
  PUTCOMPANYDATA,
} from "../../services/companyService";

export const getCompanyList = createAsyncThunk(
  "company/getCompanyList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETALLCOMPANIES(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  "company/getCompanyById",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await GETCOMPANYBYID(companyId);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (companyData, { rejectWithValue }) => {
    try {
      const response = await POSTADDCOMPANY(companyData);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const editCompanyByCompanyId = createAsyncThunk(
  "company/editCompanyByCompanyId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await PUTCOMPANYDATA(params);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

const initialState = {
  globalCompanyList: [],
  companyList: [],
  loading: false,
  company: null,
  selectedCompanyData: null,
};

const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    setSelectedCompanyData: (state, action) => {
      state.selectedCompanyData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCompanyList.pending, (state, action) => {
      state.loading = true;
      state.companyList = [];
    });
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.companyList = action.payload?.data?.value;
      state.loading = false;
    });
        builder.addCase(getCompanyList.rejected, (state, action) => {
          state.loading = false;
          state.companyList = [];
        });
    builder.addCase(getCompanyById.fulfilled, (state, action) => {
      state.company = action?.payload && action?.payload?.data;
    });
    builder.addCase(addCompany.fulfilled, (state, action) => {
      state.company = action?.payload && action?.payload?.data;
    });
    builder.addCase(editCompanyByCompanyId.fulfilled, (state, action) => {
      state.company = action?.payload && action?.payload?.data;
    });
  },
});

export const {
  getAllWorkspaceList,
  getWorkspaceByWorkspaceID,
  setSelectedCompanyData,
} = companySlice.actions;
export default companySlice.reducer;
