import React from "react";

const Spinner = ({ isLoading, dontClose }) => {
  return (
    isLoading && (
      <div
        id="spinner"
        className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-border"
          role="status"
          style={{ width: "3rem", height: "3rem", color: "#24a5a5" }}
        ></div>
        {dontClose && (
          <div>
            &nbsp;&nbsp;
            <p className="text-danger ms-2">
              <strong>
                {" "}
                Please do not refresh or close this window. Your action may
                disrupt the ongoing process...
              </strong>
            </p>
          </div>
        )}
      </div>
    )
  );
};

export default Spinner;
