import { components } from "react-select";
import React from "react";
import zIndex from "@mui/material/styles/zIndex";

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? "did-floating-label did-floating-label-top"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
export const ControlAstrisk = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
            : "did-floating-label did-floating-label did-floating-labe-astrisk"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

export const colourStylesState = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#3a4981" : "#24a5a5",
    backgroundColor: "#f6f6f6",
    color: "#3a4981",
    borderRadius: 5,
    boxShadow: "none",
    padding: "1.5px 10px",
    "&:focus": {
      border: "2px solid #3a4981 !important",
    },
    "&:active": {
      border: "2px solid #3a4981 !important",
    },
    "&:hover": {
      border: "1px solid #3a4981 !important",
    },
    "&:focus-visible": {
      border: "2px solid #red !important",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999, // set the z-index to a high value
  }),
  menuList: (base) => ({
    ...base,
    zIndex: 1999,
    height: "auto",
    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#24a5a5",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
export const multiColorStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#3a4981" : "#24a5a5",
    backgroundColor: "#f6f6f6",
    color: "#3a4981",
    borderRadius: 5,
    boxShadow: "none",
    padding: "2px 10px",
    "&:focus": {
      border: "1px solid #3a4981 !important",
    },
    "&:active": {
      border: "1px solid #3a4981 !important",
    },
    "&:hover": {
      border: "1px solid #3a4981 !important",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 200, // set the z-index to a high value
  }),
  menuList: (base) => ({
    ...base,
    height: "auto",
    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#24a5a5",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#3a459b" : isFocused ? "#E8F0FE" : undefined,
    color: isSelected ? "white" : isFocused ? "#3a4981" : "#3a4981",
    ":active": {
      backgroundColor: isSelected ? "#3a459b" : "#E8F0FE",
    },
  }),
};

export const colorStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused && "#d3eded",
      color: (isFocused && "#4041B2") || (isSelected && "#4041B2"),
    };
  },
};

export const DropdownIndicator = (props) => {
  return React.createElement(
    components.DropdownIndicator,
    props,
    React.createElement("img", { src: "/assets/images/searchIcon.svg" })
  );
};
