import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import UserListForCompanyAdmin from "./UserListForCompanyAdmin";
import CompanyAdminLayout from "../../components/CompanyAdminLayout/CompanyAdminLayout";

const CompanyAdminUserManagement = () => {
  return (
    <CompanyAdminLayout>
      <section className="outer-dash-section">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <>
                <div className="box-shadow-set">
                  <UserListForCompanyAdmin />
                </div>
              </>
            </Col>
          </Row>
        </Container>
        <div className="space-bottom">.</div>
      </section>
    </CompanyAdminLayout>
  );
};

export default CompanyAdminUserManagement;
