import { toast } from "react-toastify";
import { axiosInstance } from "./axiosInstance";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../configs/config";

export const GETALLUSERS = (groupId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/getAllUsers`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETALLUSERSBYCOMPANYID = (groupId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/getUserForCurrentCompany/${groupId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETUSERBYID = (userId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/userById/${userId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const PostMultipleFileUpload = ({ UserForm, accessToken }) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append(`csv`, UserForm?.csv);
    axios
      .post(`${REACT_APP_BACKEND_URL}/auth/importUsers`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${accessToken}`,
        },
      })
      .then((response) => {
        toast.success(response?.data?.status_message);
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(error.response?.data?.error);
        reject(error.response?.data?.data);
      });
  });

export const EXPORTUSERLIST = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/avalon/get-user-list`, params)
      .then((response) => {
        setTimeout(() => {
          resolve(response?.data);
          const data = Uint8Array.from(response?.data?.Body.data);
          const content = new Blob([data.buffer], {
            type: response?.data.ContentType,
          });

          const encodedUri = window.URL.createObjectURL(content);
          const link = document.createElement("a");

          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "UserList");

          link.click();
        }, 5000);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data?.data);
      })
  );

export const POSTADDUSER = (formData) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post("auth/add", formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.keyValue?.mobile !== "" &&
            `Please enter different mobile, Entered is already in use`
        );
        reject(error?.message);
      });
  });

export const PUTUSERDATA = (params) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post(`auth/updateUser/${params?.userId}`, params?.postData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.keyValue?.mobile !== "" &&
            `Please enter different mobile, ${error?.response?.data?.keyValue?.mobile} is already in use`
        );
        reject(error?.message);
      });
  });

export const POSTINVITEUSER = (formData) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post("auth/invite-user", formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.error ||
            "Something went wrong!, Please try again later"
        );
        reject(error?.message);
      });
  });

export const POSTREINVITEUSER = (formData) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post("/auth/reinvite-user", formData)
      .then((response) => {
        toast.success(response?.data?.status_message);
        resolve(response);
      })
      .catch((error) => {
        reject(error?.response?.data?.status_message);
        // toast.error(
        //   error.response?.data?.status_message ||
        //     "Please enter correct credentials"
        // );
      })
  );
export const POSTDELETEUSER = (params) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post("/auth/setIsDeleted", params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(
          error?.message && "Something went wrong!, Please try again later"
        );
        reject(error?.message);
      });
  });

export const GETUSERSANDADMINCOUNT = (groupId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/getUsersAndAdminCount`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message ||
            "Something went wrong!, Please try again later"
        );
        reject(error.response?.data);
      })
  );
export const GETCOMPANYADMINCOUNT = (companyId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/getCompanyAdminCount/${companyId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETTOPACTIVEUSERSFORSUPERADMIN = (count) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/getTopActiveUsersForSuperAdmin/${count}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );
export const GETTOPACTIVEUSERSFORCOMPANYADMIN = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(
        `auth/getTopActiveUsersCompanyAdmin/${params?.companyId}/${params?.count}`
      )
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETTOPREPORTSFORSUPERADMIN = (count) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`reportLog/getTopReports/${count}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );
export const GETTOPREPORTSFORCOMPANYADMIN = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(
        `reportLog/getTopReportsByGroupId/${params?.companyId}/${params?.count}`
      )
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );
