import { useState } from "react";

import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";

import {
  Box,
  Toolbar,
  Button,
  Divider,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";

import { Fullscreen, SyncAlt, Refresh, Bookmark } from "@mui/icons-material";
import {
  KeyboardArrowDown,
  Visibility,
  FitScreen,
  PhotoSizeSelectActual,
} from "@mui/icons-material";
import {
  RadioButtonUnchecked,
  RadioButtonChecked,
  FilterAlt,
  Edit,
  Pageview,
  Menu as MenuIcon,
} from "@mui/icons-material";

// ensure Power BI JavaScript API has loaded
require("powerbi-models");
require("powerbi-client");

const SuperAdminReportToolbar = ({
  report,
  showNavigation,
  setShowNavigation,
  showFiltersPane,
  setShowFiltersPane,
  showBookmarksPane,
  setShowBookmarksPane,
  viewMode,
  setViewMode,
  editMode,
  setEditMode,
}) => {
  const [anchorElementView, setAnchorElementView] = useState();
  const [anchorElementViewMode, setAnchorElementViewMode] = useState();
  const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false);

  const menuButtonProperties = { fontSize: "9px", color: "#555555", ml: 1 };
  const menuItemProps = { fontSize: "11px", px: 1.5, py: 0, m: 0 };
  const menuSwitchProps = { ml: "auto", pl: 1 };
  const menuRadioButtonProps = { ml: "auto", pl: 1.5 };

  const onViewSwitchNavigation = () => {
    setShowNavigation(!showNavigation);
    report?.updateSettings({
      panes: {
        pageNavigation: { visible: !showNavigation },
      },
    });
  };

  const onViewSwitchBookmarksPane = (args) => {
    setShowBookmarksPane(!showBookmarksPane);
    report.updateSettings({
      panes: {
        bookmarks: { visible: !showBookmarksPane },
      },
    });
  };

  const onViewSwitchFilterPaneView = () => {
    setShowFiltersPane(!showFiltersPane);
    report?.updateSettings({
      panes: {
        filters: { visible: !showFiltersPane, expanded: true },
      },
    });
  };

  const onViewModeFitToPage = () => {
    setAnchorElementViewMode(null);
    report?.updateSettings({
      layoutType: models.LayoutType.Custom,
      customLayout: { displayOption: models.DisplayOption.FitToPage },
    });
    setViewMode("FitToPage");
  };

  const onViewModeFitToWidth = () => {
    setAnchorElementViewMode(null);
    report?.updateSettings({
      layoutType: models.LayoutType.Custom,
      customLayout: { displayOption: models.DisplayOption.FitToWidth },
    });
    setViewMode("FitToWidth");
  };

  const onViewModeActualSize = () => {
    setAnchorElementViewMode(null);
    report?.updateSettings({
      layoutType: models.LayoutType.Custom,
      customLayout: { displayOption: models.DisplayOption.ActualSize },
    });
    setViewMode("ActualSize");
  };

  const onReportRefresh = () => {
    setRefreshButtonDisabled(true);
    setTimeout(() => {
      setRefreshButtonDisabled(false);
    }, 15000);
    report?.refresh();
  };

  const onReportFullscreen = () => {
    report?.fullscreen();
  };

  return (
    <>
      <Box
        sx={{
          width: 1,
          backgroundColor: "#CCCCCC",
          p: 0,
          m: 0,
          maxHeight: "32px",
        }}
      >
        <Toolbar
          disableGutters
          variant="dense"
          sx={{ p: 0, m: 0, minHeight: "32px" }}
        >
          <Divider orientation="vertical" flexItem sx={{ pl: 0.5 }} />

          <Button
            startIcon={<Visibility />}
            endIcon={<KeyboardArrowDown />}
            sx={menuButtonProperties}
            onClick={(event) => {
              setAnchorElementView(event.currentTarget);
            }}
          >
            View
          </Button>
          <Menu
            sx={menuItemProps}
            anchorEl={anchorElementView}
            open={Boolean(anchorElementView)}
            onClose={() => {
              setAnchorElementView(null);
            }}
          >
            <MenuItem sx={menuItemProps} disableRipple>
              <MenuIcon sx={{ mr: 1 }} /> Navigation Menu{" "}
              <Switch
                sx={menuSwitchProps}
                checked={Boolean(showNavigation)}
                onClick={onViewSwitchNavigation}
              />
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem sx={menuItemProps} disableRipple>
              <Bookmark sx={{ mr: 1 }} /> Bookmarks{" "}
              <Switch
                sx={menuSwitchProps}
                checked={showBookmarksPane}
                onClick={onViewSwitchBookmarksPane}
              />
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem sx={menuItemProps} disableRipple>
              <FilterAlt sx={{ mr: 1 }} /> Filter Pane{" "}
              <Switch
                sx={menuSwitchProps}
                checked={showFiltersPane}
                onClick={onViewSwitchFilterPaneView}
              />
            </MenuItem>
          </Menu>
          <Divider orientation="vertical" flexItem />

          <Divider orientation="vertical" flexItem />

          <Divider orientation="vertical" flexItem sx={{ ml: "auto" }} />

          <Button
            startIcon={<FitScreen />}
            endIcon={<KeyboardArrowDown />}
            sx={menuButtonProperties}
            onClick={(event) => {
              setAnchorElementViewMode(event.currentTarget);
            }}
          >
            View Mode
          </Button>
          <Menu
            sx={menuItemProps}
            anchorEl={anchorElementViewMode}
            open={Boolean(anchorElementViewMode)}
            onClose={() => {
              setAnchorElementViewMode(null);
            }}
          >
            <MenuItem
              onClick={onViewModeFitToPage}
              disableRipple
              sx={menuItemProps}
            >
              <FitScreen sx={{ mr: 1 }} /> Fit to Page{" "}
              {viewMode === "FitToPage" ? (
                <RadioButtonChecked sx={menuRadioButtonProps} />
              ) : (
                <RadioButtonUnchecked sx={menuRadioButtonProps} />
              )}
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              onClick={onViewModeFitToWidth}
              disableRipple
              sx={menuItemProps}
            >
              <SyncAlt sx={{ mr: 1 }} /> Fit to Width{" "}
              {viewMode === "FitToWidth" ? (
                <RadioButtonChecked sx={menuRadioButtonProps} />
              ) : (
                <RadioButtonUnchecked sx={menuRadioButtonProps} />
              )}
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              onClick={onViewModeActualSize}
              disableRipple
              sx={menuItemProps}
            >
              <PhotoSizeSelectActual sx={{ mr: 1 }} /> Actual Size{" "}
              {viewMode === "ActualSize" ? (
                <RadioButtonChecked sx={menuRadioButtonProps} />
              ) : (
                <RadioButtonUnchecked sx={menuRadioButtonProps} />
              )}
            </MenuItem>
          </Menu>

          <Button
            startIcon={<Refresh />}
            sx={menuButtonProperties}
            onClick={onReportRefresh}
            disabled={refreshButtonDisabled}
          >
            Refresh
          </Button>

          <Divider orientation="vertical" flexItem />

          <Button
            startIcon={<Fullscreen />}
            sx={menuButtonProperties}
            onClick={onReportFullscreen}
          >
            Full Screen
          </Button>

          <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
        </Toolbar>
      </Box>
    </>
  );
};

export default SuperAdminReportToolbar;
