import { toast } from "react-toastify";
import { axiosInstance } from "./axiosInstance";

export const GETALLJOBTITLE = (groupId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/userprincipal/userprincipals`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETJOBTITLEBYCOMPANYID = (companyId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post(`/job/getJobsByCompanyId`, { company_id: companyId })
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const POSTADDJOBTITLE = (formData) =>
  new Promise((resolve, reject) => {
    return axiosInstance
      .post("/job/addJob", formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });

export const GETJOBTITLEBYID = (jobTitleId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/job/job/${jobTitleId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const PUTJOBTITLEDATA = (params) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .put(`/job/updateJob`, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });

export const DELETEJOBTITLEDATA = (jobTitleId) =>
  new Promise((resolve, reject) => {
    return axiosInstance
      .delete(
        `/job/job/${jobTitleId}
`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(
          error?.message && "Something went wrong!, Please try again later"
        );
        reject(error?.message);
      });
  });
