import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import SuperAdminLayout from "../../components/SuperAdminLayout/SuperAdminLayout";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/layout/shared/Spinner";
import {
  getTopActiveUsersForSuperAdmin,
  getTopViewedReportsForSuperAdmin,
  getUserAndAdminCount,
} from "../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { firstCapitalLetter } from "../../utils/commonFunction";
ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend
);

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);
  const topActiveUsers = useSelector(({ user }) => user.topActiveUsers);
  const topViewedReports = useSelector(({ user }) => user.topViewedReports);

  const data1 = {
    labels:
      topViewedReports &&
      topViewedReports?.map((report, index) => report?.reportName || "Report"),
    datasets: [
      {
        label: "Number of visit",
        data:
          topViewedReports &&
          topViewedReports?.map((report, index) => report?.count),
        backgroundColor: [
          "#3a4981",
          "#256aad",
          "#45b6fe",
          "#00004d",
          "#24a5a5",
        ],
        borderColor: ["#3a4981", "#256aad", "#45b6fe", "#00004d", "#24a5a5"],
        borderWidth: 1,
      },
    ],
  };

  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `Top ${
          topViewedReports?.length || 5
        } reports by number of visits`,
        align: "start",
        font: {
          size: 16,
        },
      },
      // tooltip: {
      //   titleFont: {
      //     size: 16,
      //   },
      //   bodyFont: {
      //     size: 14,
      //   },
      // },
      legend: {
        display: true,
        // responsive: true,
        position: "right",
        // rtl: true,
        labels: {
          boxWidth: 36,
          padding: 10,
          font: {
            size: 14,
          },
        },
        align: "center",
      },
    },
  };

  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );
  const companyLoading = useSelector(({ company }) => company.loading);
  const usersCount = useSelector(({ user }) => user.usersCount);
  const companyAdminCount = useSelector(({ user }) => user.companyAdminCount);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserAndAdminCount())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    //Change count if you want to get more
    dispatch(getTopActiveUsersForSuperAdmin(5))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    dispatch(getTopViewedReportsForSuperAdmin(5))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const goToWorkspace = () => {
    navigate("/superadmin/company-management");
  };

  const goTousers = (search) => {
    navigate("/superadmin/user-management", { state: { search: search } });
  };

  return (
    <>
      <SuperAdminLayout>
        <section className="outer-dash-section">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <div className="dashboard-white-box">
                  <div className="w-100">
                    <h2 className="inner-page-heading">Dashboard</h2>
                    <Row>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <div
                          className="shadow-box-dash d-flex align-items-center justify-content-between"
                          onClick={goToWorkspace}
                        >
                          <div>
                            <h4>{companyListFromReducer?.length}</h4>
                            <p>
                              {companyListFromReducer?.length > 1
                                ? "Companies"
                                : "Company"}
                            </p>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="6em"
                              viewBox="0 0 576 512"
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "10px",
                                height: "90px",
                                width: "90px",
                                opacity: "0.5",
                                color: "#14877c",
                              }}
                            >
                              <path d="M208 80c0-26.5 21.5-48 48-48h64c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48h-8v40H464c30.9 0 56 25.1 56 56v32h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H464c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V288c0-4.4-3.6-8-8-8H312v40h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H256c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V280H112c-4.4 0-8 3.6-8 8v32h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V288c0-30.9 25.1-56 56-56H264V192h-8c-26.5 0-48-21.5-48-48V80z" />
                            </svg>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <div
                          className="shadow-box-dash d-flex align-items-center justify-content-between"
                          onClick={() => {
                            goTousers("COMPANYADMIN");
                          }}
                        >
                          <div>
                            <h4>{companyAdminCount}</h4>
                            <p>{companyAdminCount > 1 ? "Admins" : "Admin"}</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="6em"
                              viewBox="0 0 640 512"
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "10px",
                                height: "90px",
                                width: "90px",
                                opacity: "0.5",
                                color: "#14877c",
                              }}
                            >
                              <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c1.8 0 3.5-.2 5.3-.5c-76.3-55.1-99.8-141-103.1-200.2c-16.1-4.8-33.1-7.3-50.7-7.3H178.3zm308.8-78.3l-120 48C358 277.4 352 286.2 352 296c0 63.3 25.9 168.8 134.8 214.2c5.9 2.5 12.6 2.5 18.5 0C614.1 464.8 640 359.3 640 296c0-9.8-6-18.6-15.1-22.3l-120-48c-5.7-2.3-12.1-2.3-17.8 0zM591.4 312c-3.9 50.7-27.2 116.7-95.4 149.7V273.8L591.4 312z" />
                            </svg>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={12} sm={12} xs={12}>
                        <div
                          className="shadow-box-dash d-flex align-items-center justify-content-between"
                          onClick={() => {
                            goTousers("USER");
                          }}
                        >
                          <div>
                            <h4>{usersCount}</h4>
                            <p>{usersCount > 1 ? "Users" : "User"}</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="6em"
                              viewBox="0 0 640 512"
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "10px",
                                height: "90px",
                                width: "90px",
                                opacity: "0.5",
                                color: "#14877c",
                              }}
                            >
                              <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                            </svg>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={12} sm={12} xs={12}>
                        <div
                          onClick={() => {
                            navigate("/superadmin/user-management");
                          }}
                          className="shadow-box-dash set-height-dash d-sm-flex align-items-center justify-content-between"
                        >
                          <div className="listing-box ">
                            <h4>
                              Top {topActiveUsers?.length || 0} Active{" "}
                              {topActiveUsers?.length === 1 ? "User" : "Users"}
                            </h4>
                            <ul>
                              {topActiveUsers?.length &&
                                topActiveUsers?.map((user, index) => {
                                  return (
                                    <li key={user?._id}>
                                      <span>
                                        {" "}
                                        {user?.firstName && user?.lastName
                                          ? firstCapitalLetter(
                                              user?.firstName
                                            ) +
                                            " " +
                                            firstCapitalLetter(user?.lastName)
                                          : "-N/A-"}
                                      </span>
                                      &nbsp;
                                      <span>
                                        (
                                        {user?.company?.company_name
                                          ? user?.company?.company_name
                                          : "-N/A-"}
                                        )
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="6em"
                              viewBox="0 0 640 512"
                              style={{
                                position: "",
                                right: "20px",
                                top: "10px",
                                height: "110px",
                                width: "110px",
                                opacity: "0.5",
                                color: "#14877c",
                              }}
                            >
                              <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
                            </svg>
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} md={12} sm={12} xs={12}>
                        <div className="shadow-box-dash set-height-dash">
                          <Doughnut
                            style={{ height: "250px", width: "100%" }}
                            data={data1}
                            options={options1}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="space-bottom">.</div>
        </section>
        <Spinner isLoading={loading || companyLoading || reportLoading} />
      </SuperAdminLayout>
    </>
  );
};

export default SuperAdminDashboard;
