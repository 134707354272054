import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/layout/shared/Spinner";

import { Box, Container, Tooltip, Typography } from "@mui/material";

import { getCompanyList } from "../../redux/slice/companySlice";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";

const CompanyListForSuperAdmin = () => {
  const [loading, setLoading] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);
  const companyLoading = useSelector(({ company }) => company.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );
  const companyListRef = useRef();
  companyListRef.current =
    companyListFromReducer?.length && companyListFromReducer;

  const columns = [
    {
      accessorKey: "name",
      header: "Company Name",
      Cell: ({ cell }) => {
        const companyName = cell.getValue() || "-N/A-";
        return (
          <Tooltip title={companyName}>
            <span>{companyName}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Company Type",
      Cell: ({ cell }) => {
        const companyType = cell.getValue() || "-N/A-";
        return (
          <Tooltip title={companyType}>
            <span>{companyType}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "defaultDatasetStorageFormat",
      header: "Default Dataset Storage Format",
      Cell: ({ cell }) => {
        const storageFormat = cell.getValue() || "-N/A-";
        return (
          <Tooltip title={storageFormat}>
            <span>{storageFormat}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "id",
      header: "Company ID",
      Cell: ({ cell }) => {
        const companyId = cell.getValue() || "-N/A-";
        return (
          <Tooltip title={companyId}>
            <span>{companyId}</span>
          </Tooltip>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: companyListFromReducer || [], // Assuming 'collection' is your data source
    enableDensityToggle: false,
    enableSorting: false,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: false,
    enableHiding: false,

    initialState: {
      density: "compact",
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 16.2rem)",
        transition: "max-height 0.5s ease-in-out",
      },
    },
    muiTopToolbarProps: {
      backgroundColor: "black",
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #3a4981",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
    },

    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#F6f6f6",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "rgba(65, 213, 213, 0.1)",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          padding: "0.5rem",
        }}
      >
        <Typography
          variant="h6"
          style={{ color: "darkblue", fontWeight: "bold" }}
        >
          Companies
        </Typography>
        <MRT_GlobalFilterTextField table={table} />
      </Box>
    ),
  });

  return (
    <>
      <Container className="p-0 " style={{ maxWidth: "100%" }}>
        <MaterialReactTable table={table} />
      </Container>

      <Spinner isLoading={loading || companyLoading || reportLoading} />
    </>
  );
};

export default CompanyListForSuperAdmin;
