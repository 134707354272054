import UserLayout from "../../components/UserLayout/UserLayout";
import React from "react";
import { Container, Alert, Typography } from "@mui/material";
import Spinner from "../../components/layout/shared/Spinner";
import { useSelector } from "react-redux";

const UserDashboard = () => {
  const reportLoading = useSelector(({ report }) => report.loading);
  return (
    <UserLayout>
      <section className="outer-dash-section">
        <Container maxWidth={false}>
          <Typography variant="h5" component="h2" sx={{ my: 3 }}>
            Congratulations - You are now authenticated
          </Typography>
          <Alert sx={{ border: 1, padding: 2, mx: 2 }} severity="success">
            Now, you can now use the left navigation menu to navigate to open
            reports.
          </Alert>
        </Container>
        <div className="space-bottom">.</div>
      </section>
      <Spinner isLoading={reportLoading} />
    </UserLayout>
  );
};

export default UserDashboard;
