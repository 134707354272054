import {
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";
import { useDispatch, useSelector } from "react-redux";
import CompanyAdminLayout from "../../components/CompanyAdminLayout/CompanyAdminLayout";
import CompanyAdminReportToolbar from "./CompanyAdminReportToolbar";
import Spinner from "../../components/layout/shared/Spinner";
import { getUpnNamesByCompanyId } from "../../redux/slice/upnSlice";
import { GENERATEEMBEDTOKEN } from "../../services/reportService";

// Ensure Power BI JavaScript API has loaded
require("powerbi-models");
require("powerbi-client");

const CompanyAdminReport = () => {
  const location = useLocation();
  const embedContainer = useRef(null);
  const dispatch = useDispatch();

  const userPrincipalNameList = useSelector(
    ({ upn }) => upn.userPrincipalNameList
  );
  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);
  const reportLoading = useSelector(({ report }) => report.loading);

  const [embeddedReport, setEmbeddedReport] = useState(null);
  const [embedType, setEmbedType] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showNavigation, setShowNavigation] = useState(true);
  const [showFiltersPane, setShowFiltersPane] = useState(true);
  const [showBookmarksPane, setShowBookmarksPane] = useState(false);
  const [viewMode, setViewMode] = useState("FitToPage");
  const [accessToken, setAccessToken] = useState("");

  const report = location?.state?.report;

  // Fetch UPN names by company ID
  useEffect(() => {
    if (loggedInUser?.company?.company_id) {
      setLoading(true);
      dispatch(getUpnNamesByCompanyId(loggedInUser.company.company_id))
        .then(() => setLoading(false))
        .catch((err) => {
          console.error("Error fetching UPN names:", err);
          setLoading(false);
        });
    }
  }, [dispatch, loggedInUser]);

  // Generate embed token when report and userPrincipalNameList are ready
  // Power BI RLS to grenerate token only takes maximum upto 50 roles
  useEffect(() => {
    if (loggedInUser && userPrincipalNameList?.length) {
      const formData = {
        reportId: report?.id,
        groupId: report?.datasetWorkspaceId,
        accessLevel: "View",
        roles: userPrincipalNameList
          ?.slice(0, 50)
          ?.map((upn) => upn?.user_principal_name),
        datasets: [report?.datasetId],
        username: loggedInUser?.email,
        reportName: report?.name,
      };

      GENERATEEMBEDTOKEN(formData)
        .then((res) => setAccessToken(res?.data?.data?.token))
        .catch((err) => {
          console.error("Error generating embed token:", err);
        });
    }
  }, [loggedInUser, report, userPrincipalNameList]);

  // Embed the report when access token is available
  const embedExistingReport = useCallback(
    (ReportId) => {
      try {
        setLoading(true);

        // Reset any previously embedded report
        if (embedContainer.current && window.powerbi) {
          window.powerbi.reset(embedContainer.current);
        }

        const defaultShowNavigation = true;
        const defaultShowFilterPane = false;
        const defaultShowBookmarksPane = false;
        const defaultViewMode = "FitToPage";

        setEmbedType("ExistingReport");
        setReportType("PowerBiReport");
        setShowNavigation(defaultShowNavigation);
        setShowFiltersPane(defaultShowFilterPane);
        setShowBookmarksPane(defaultShowBookmarksPane);
        setViewMode(defaultViewMode);

        const config = {
          type: "report",
          id: ReportId,
          embedUrl: report?.embedUrl,
          accessToken: accessToken,
          tokenType: models.TokenType.Embed,
          viewMode: editMode ? models.ViewMode.Edit : models.ViewMode.View,
          permissions: models.Permissions.All,
          settings: {
            panes: {
              pageNavigation: {
                visible: defaultShowNavigation,
                position: models.PageNavigationPosition.Left,
              },
              filters: { visible: defaultShowFilterPane, expanded: false },
              bookmarks: { visible: defaultShowBookmarksPane },
            },
            bars: {
              actionBar: { visible: false },
            },
            persistentFiltersEnabled: true,
            personalBookmarksEnabled: true,
          },
        };

        // Embed the report
        const embeddedReportInstance = window.powerbi.embed(
          embedContainer.current,
          config
        );

        embeddedReportInstance.on("rendered", () => {
          setLoading(false);
        });

        embeddedReportInstance.on("error", (err) => {
          console.error("Error rendering report:", err);
          setLoading(false);
        });

        setEmbeddedReport(embeddedReportInstance);
      } catch (error) {
        console.error("Error embedding report:", error);
        setLoading(false);
        setEmbeddedReport(null);
      }
    },
    [accessToken, editMode, report?.embedUrl]
  );

  // Embed the report when access token is available
  useEffect(() => {
    if (accessToken) {
      embedExistingReport(report?.id);
    }
  }, [accessToken, report?.id, embedExistingReport]);

  // Set height of embed container relative to the height of the window
  const setReportContainerHeight = () => {
    if (embedContainer.current) {
      const reportContainer = embedContainer.current;
      const reportContainerTopPosition =
        reportType === "PaginatedReport" ? 88 : 122;
      reportContainer.style.height = `${
        window?.innerHeight - reportContainerTopPosition - 10
      }px`;
    }
  };

  useLayoutEffect(() => {
    if (report?.id) {
      setReportContainerHeight();
      window.addEventListener("resize", setReportContainerHeight);
    }

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", setReportContainerHeight);
    };
  }, [report?.id, reportType]);

  return (
    <CompanyAdminLayout>
      <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
        {embedType === "ExistingReport" &&
          reportType === "PowerBiReport" &&
          embeddedReport !== null && (
            <CompanyAdminReportToolbar
              editMode={editMode}
              setEditMode={setEditMode}
              report={embeddedReport}
              showNavigation={showNavigation}
              setShowNavigation={setShowNavigation}
              showFiltersPane={showFiltersPane}
              setShowFiltersPane={setShowFiltersPane}
              viewMode={viewMode}
              setViewMode={setViewMode}
              showBookmarksPane={showBookmarksPane}
              setShowBookmarksPane={setShowBookmarksPane}
            />
          )}

        <Box
          ref={embedContainer}
          sx={{ width: "100%", minHeight: "40.8333vh" }}
        />
      </Box>
      <Spinner isLoading={loading || reportLoading} />
    </CompanyAdminLayout>
  );
};

export default CompanyAdminReport;
