import { combineReducers } from "@reduxjs/toolkit";

import universalLoadingSlice from "./loadingSlice";
import authSlice from "./authSlice";
import reportSlice from "./reportSlice";
import userSlice from "./userSlice";
import companySlice from "./companySlice";
import upnSlice from "./upnSlice";
import jobTitleSlice from "./jobTitleSlice";

const combinedReducer = combineReducers({
  auth: authSlice,
  loading: universalLoadingSlice,
  report: reportSlice,
  user: userSlice,
  company: companySlice,
  upn: upnSlice,
  jobTitle: jobTitleSlice,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;
