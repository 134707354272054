import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { changePasswordSchema } from "../../utils/validationSchema/validation";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../../redux/slice/userSlice";
import { resetPassword } from "../../redux/slice/authSlice";
import { toast } from "react-toastify";
import Spinner from "../../components/layout/shared/Spinner";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../configs/config";

const ResetPassword = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const toggleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmNewPassword((prev) => !prev);
  };

  const defaultValues = {
    new_password: "",
    confirm_password: "",
  };

  // ** Hooks
  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(changePasswordSchema),
  });

  const { control, handleSubmit, formState } = methods;
  const { errors } = formState;

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(
      window.location.search.replace(/&amp;/g, "&")
    );

    const userId = urlParams.get("userid");
    const userToken = urlParams.get("token");

    if (userId === undefined || userToken === undefined) {
      // Redirect if userId or userToken is not defined
      navigate("/", { replace: true });
    } else {
      // Encrypt and set token in localStorage
      const encryptedToken = CryptoJS.AES.encrypt(
        userToken,
        REACT_APP_CRYPTOJS_SECRETKEY,
        {
          REACT_APP_CRYPTOJS_VECTOR,
        }
      ).toString();

      localStorage.setItem("accessToken", encryptedToken);

      // Make API request to get user data
      dispatch(getUserById(userId))
        .then((res) => {
          if (res?.error?.message === "Rejected") {
            // If user fetch is rejected, throw an error
            throw new Error("User fetch rejected");
          }

          // Continue processing if user fetch is successful
          // Additional code as needed after dispatch
        })
        .catch((error) => {
          // Handle errors during API request or user fetch
          console.error("Error in useEffect:", error);
          localStorage.removeItem("accessToken");
          navigate("/", { replace: true });
        });
    }
  }, [dispatch, navigate]);

  const onSubmit = async (data) => {
    const params = {
      password: data?.confirm_password,
    };

    setLoading(true);

    try {
      const userData = await dispatch(resetPassword(params));

      if (
        !userData?.payload ||
        userData?.payload === undefined ||
        userData?.payload?.data?.match === false
      ) {
        return toast.error("Please enter correct credentials");
      } else {
        toast.success(
          "Credentials has been updated successfully! , Login with new credentials!"
        );
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred during password update");
    } finally {
      setLoading(false);
      localStorage.removeItem("accessToken");
    }
  };

  return (
    <div className="login-section">
      <Container fluid style={{ paddingLeft: "0" }}>
        <Row className="align-items-center">
          <Col md={5} className="p-0">
            <div className="text-center response-image">
              <img
                src={"/assets/images/white_logo.png"}
                className="img-fluid"
                alt="icon"
              />
            </div>
          </Col>
          <Col md="5" className="m-auto">
            <div className="shadow-box">
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="login-form">
                    <h3>Update Password</h3>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group
                        className="did-floating-label-content mb-3"
                        controlId="new_password"
                      >
                        <Controller
                          control={control}
                          name="new_password"
                          render={({ field }) => (
                            <input
                              className="did-floating-input"
                              placeholder=" "
                              type={showNewPassword ? "text" : "password"}
                              value={field?.value}
                              onChange={field?.onChange}
                            />
                          )}
                        />
                        <label className="did-floating-label">
                          New Password*
                        </label>
                        {showNewPassword ? (
                          <span
                            onClick={toggleShowNewPassword}
                            id="#password-field"
                            style={{
                              color: "#24a5a5",
                              float: "right",
                              marginLeft: "-25px",
                              marginTop: "8px",
                              position: "relative",
                              right: "10px",
                              zIndex: "2",
                              cursor: "pointer",
                            }}
                            className="fa fa-fw fa-eye field-icon toggle-password"
                          ></span>
                        ) : (
                          <span
                            onClick={toggleShowNewPassword}
                            style={{
                              color: "#24a5a5",
                              float: "right",
                              marginLeft: "-25px",
                              marginTop: "8px",
                              position: "relative",
                              right: "10px",
                              zIndex: "2",
                              cursor: "pointer",
                            }}
                            id="#password-field"
                            className="fa fa-fw fa-eye-slash field-icon toggle-password"
                          ></span>
                        )}
                        {errors.new_password && (
                          <Form.Text className="text-danger">
                            {errors.new_password.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="did-floating-label-content mb-3"
                        controlId="confirm_password"
                      >
                        <Controller
                          control={control}
                          name="confirm_password"
                          render={({ field }) => (
                            <input
                              className="did-floating-input"
                              placeholder=" "
                              type={
                                showConfirmNewPassword ? "text" : "password"
                              }
                              value={field?.value}
                              onChange={field?.onChange}
                            />
                          )}
                        />
                        <label className="did-floating-label">
                          Confirm New Password*
                        </label>
                        {showConfirmNewPassword ? (
                          <span
                            onClick={toggleShowConfirmPassword}
                            id="#password-field"
                            style={{
                              color: "#24a5a5",
                              float: "right",
                              marginLeft: "-25px",
                              marginTop: "8px",
                              position: "relative",
                              right: "10px",
                              zIndex: "2",
                              cursor: "pointer",
                            }}
                            className="fa fa-fw fa-eye field-icon toggle-password"
                          ></span>
                        ) : (
                          <span
                            onClick={toggleShowConfirmPassword}
                            style={{
                              color: "#24a5a5",
                              float: "right",
                              marginLeft: "-25px",
                              marginTop: "8px",
                              position: "relative",
                              right: "10px",
                              zIndex: "2",
                              cursor: "pointer",
                            }}
                            id="#password-field"
                            className="fa fa-fw fa-eye-slash field-icon toggle-password"
                          ></span>
                        )}
                        {errors.confirm_password && (
                          <Form.Text className="text-danger">
                            {errors.confirm_password.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div>
                        <button
                          className={`login-btn w-100 m-0 ${
                            loading ? "disabled" : ""
                          }`}
                          type="submit" // Change to type="submit" to trigger form submission
                          disabled={loading} // Disable the button while loading
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading} />
    </div>
  );
};

export default ResetPassword;
