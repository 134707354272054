import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  ADDACTIVEMINUTES,
  GETPROFILEBYUSERID,
  LOGOUT,
  PasswordTriggerEmail,
  PostChangePassword,
  PostLogin,
  PostOtpVerify,
  PostRegister,
  PostResetPassword,
  PostToggleActive,
  UPDATEMYPROFILE,
} from "../../services/auth";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../configs/config";

export const userRegister = createAsyncThunk(
  "auth/userRegister",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await PostRegister(credential);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const getProfileById = createAsyncThunk(
  "user/getProfileById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETPROFILEBYUSERID(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (params, { rejectWithValue }) => {
    try {
      const response = await UPDATEMYPROFILE(params);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const userLogin = createAsyncThunk(
  "auth/userLogin",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await PostLogin(credential);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);
export const toggleUserActive = createAsyncThunk(
  "auth/toggleUserActive",
  async (params, { rejectWithValue }) => {
    try {
      const response = await PostToggleActive(params);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await PostChangePassword(credential);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await PostResetPassword(credential);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const verifyUserOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await PostOtpVerify(credential);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const getProfileByUserId = createAsyncThunk(
  "auth/getProfileByUserId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETPROFILEBYUSERID(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);
export const addActiveMinutesToCurrentUser = createAsyncThunk(
  "auth/addActiveMinutesToCurrentUser",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ADDACTIVEMINUTES(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/userLogout",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LOGOUT(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const forgotPasswordTriggerEmail = createAsyncThunk(
  "auth/forgotPasswordTriggerEmail",
  async (email, { rejectWithValue }) => {
    try {
      const response = await PasswordTriggerEmail(email);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

const initialState = {
  loggedInUser: null,
  token: null,
  isLoggedIn: null,
  isOtpVerified: false,
  currentUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      state.loggedInUser = action.payload.user;
      state.isOtpVerified = action?.payload?.isOtpVerified;
      state.token = action.payload.token;
      state.isLoggedIn = true;
      localStorage.setItem(
        "accessToken",
        CryptoJS.AES.encrypt(
          action?.payload?.token,
          REACT_APP_CRYPTOJS_SECRETKEY,
          {
            REACT_APP_CRYPTOJS_VECTOR,
          }
        ).toString()
      );
      localStorage.setItem(
        "user",
        CryptoJS.AES.encrypt(
          JSON.stringify(action.payload.user),
          REACT_APP_CRYPTOJS_SECRETKEY,
          {
            REACT_APP_CRYPTOJS_VECTOR,
          }
        ).toString()
      );
    },
    verifyOtp: (state, action) => {
      state.isOtpVerified = action.payload.isOtpVerified;
    },
    logout: (state) => {
      state.loggedInUser = {};
      state.token = null;
      state.isLoggedIn = false;
      state.isOtpVerified = false;
      state.currentUser = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.token = action?.payload?.token;
      state.isOtpVerified =
        action?.payload?.isVerified && action?.payload?.active;
      state.isLoggedIn = action?.payload?.isVerified && action?.payload?.active;
      state.loggedInUser =
        action?.payload?.isVerified && action?.payload?.payload;

      localStorage.setItem(
        "accessToken",
        CryptoJS.AES.encrypt(
          action?.payload?.token,
          REACT_APP_CRYPTOJS_SECRETKEY,
          {
            REACT_APP_CRYPTOJS_VECTOR,
          }
        ).toString()
      );
      action?.payload?.isVerified &&
        localStorage.setItem(
          "user",
          CryptoJS.AES.encrypt(
            JSON.stringify({
              ...action?.payload?.payload,
            }),
            REACT_APP_CRYPTOJS_SECRETKEY,
            {
              REACT_APP_CRYPTOJS_VECTOR,
            }
          ).toString()
        );
    });
    builder.addCase(getProfileById.fulfilled, (state, action) => {
      state.currentUser = action?.payload;
    });
    // builder.addCase(addActiveMinutesToCurrentUser.pending, (state, action) => {
    //   state.currentUser = null;
    // });
    // builder.addCase(
    //   addActiveMinutesToCurrentUser.fulfilled,
    //   (state, action) => {
    //     state.loggedInUser = action?.payload;
    //     localStorage.setItem(
    //       "user",
    //       CryptoJS.AES.encrypt(
    //         JSON.stringify(action.payload),
    //         REACT_APP_CRYPTOJS_SECRETKEY,
    //         {
    //           REACT_APP_CRYPTOJS_VECTOR,
    //         }
    //       ).toString()
    //     );
    //   }
    // );
    builder.addCase(addActiveMinutesToCurrentUser.rejected, (state, action) => {
      state.currentUser = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.currentUser = action?.payload;
      state.isOtpVerified = action?.payload?.active;
      state.isLoggedIn = action?.payload?.active;
      state.loggedInUser = action?.payload;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      // state.loggedInUser = {};
      // state.token = null;
      // state.isLoggedIn = false;
      // state.isOtpVerified = false;
      // state.currentUser = null;
      // localStorage.removeItem("accessToken");
      // localStorage.removeItem("user");
    });

    builder.addCase(verifyUserOtp.fulfilled, (state, action) => {
      state.isOtpVerified = action?.payload?.active;
      state.isLoggedIn = action?.payload?.active;
      state.loggedInUser = action?.payload;
      localStorage.setItem(
        "user",
        CryptoJS.AES.encrypt(
          JSON.stringify({
            ...action?.payload,
          }),
          REACT_APP_CRYPTOJS_SECRETKEY,
          {
            REACT_APP_CRYPTOJS_VECTOR,
          }
        ).toString()
      );
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      state.loggedInUser = null;
      state.token = null;
      state.isLoggedIn = false;
      state.isOtpVerified = false;
      state.currentUser = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.loggedInUser = null;
      state.token = null;
      state.isLoggedIn = false;
      state.isOtpVerified = false;
      state.currentUser = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    });
  },
});

export const { logout, login, verifyOtp } = authSlice.actions;

export default authSlice.reducer;
