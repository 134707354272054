import React from "react";
import { Modal } from "react-bootstrap";

const ModalWrapper = (props) => {
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.handleClose}
        size={props.size}
        backdrop={props?.keyboard && "static"}
        keyboard={props?.keyboard && false}
      >
   
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
};

export default ModalWrapper;
