import { toast } from "react-toastify";

import { axiosInstance } from "./axiosInstance";

export const PostRegister = (credential) =>
  new Promise((resolve, reject) => {
    return axiosInstance
      .post("auth/user-onboard", credential)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(
          error?.message && "Something went wrong, Please try again!"
        );
        reject(error?.message);
      });
  });

export const PostLogin = (credential) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post("auth/login", credential)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
        reject(error?.message);
      });
  });

export const PostToggleActive = (params) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post("auth/setActive", params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
        reject(error?.message);
      });
  });

export const PostForgotPassword = (params) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post("/auth/forgotPassword", params)
      .then((response) => {
        toast.success(response?.data?.status_message);
        resolve(response);
      })
      .catch((error) => {
        reject(error.response?.data?.status_message);
        toast.error(error.response?.data?.status_message);
      })
  );
export const PostOtpVerify = (credential) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post("/auth/verifyOTP", credential)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response?.data?.status_message);
        toast.error(error.response?.data?.status_message);
      })
  );

export const GETPROFILEBYUSERID = (userId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/userProfileById/${userId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );
export const ADDACTIVEMINUTES = (userId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/addActiveMinute`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const RESENDOTP = () =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/sendOtp`)
      .then((response) => {
        if (response?.data?.accepted?.length) {
          toast.success(
            `Security code has been sent to : ${response?.data?.accepted?.[0]}`
          );
        }
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const UPDATEMYPROFILE = (params) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post(`auth/updateProfile`, params?.postData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });

export const LOGOUT = (userId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/logout/${userId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const PostChangePassword = (credential) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post("/auth/changePassword", credential)
      .then((response) => {
        toast.success(response?.data?.status_message);
        resolve(response);
      })
      .catch((error) => {
        reject(error?.response?.data?.status_message);
        // toast.error(
        //   error.response?.data?.status_message ||
        //     "Please enter correct credentials"
        // );
      })
  );

export const PostResetPassword = (credential) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .post("/auth/updatePassword", credential)
      .then((response) => {
        toast.success(response?.data?.status_message);
        resolve(response);
      })
      .catch((error) => {
        reject(error.response?.data?.status_message);
        toast.error(error.response?.data?.status_message);
      })
  );

export const PasswordTriggerEmail = (email) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/auth/passwordReset/${email}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.error ||
            error.response?.data?.status_message ||
            "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );
