import {
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useLocation, useParams } from "react-router-dom";

import { GENERATEEMBEDTOKEN } from "../../services/reportService";

import { Box } from "@mui/material";

import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";
import { useSelector } from "react-redux";
import UserLayout from "../../components/UserLayout/UserLayout";
import UserReportToolbar from "./UserReportToolbar";
import Spinner from "../../components/layout/shared/Spinner";

// Ensure Power BI JavaScript API has loaded
require("powerbi-models");
require("powerbi-client");

const UserReport = () => {
  const location = useLocation();
  const embedContainer = useRef(null);
  const params = useParams();

  const [embeddedReport, setEmbeddedReport] = useState(null);
  const [embedType, setEmbedType] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [loading, setLoading] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);
  const [showNavigation, setShowNavigation] = useState(true);
  const [showFiltersPane, setShowFiltersPane] = useState(true);
  const [showBookmarksPane, setShowBookmarksPane] = useState(false);
  const [viewMode, setViewMode] = useState("FitToPage");

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  const [accessToken, setAccessToken] = useState("");
  const report = location?.state?.report;

  console.log("report", report);

  // Inside your component
  const embedExistingReport = useCallback(
    (ReportId, accessToken) => {
      try {
        setLoading(true);

        // Clear existing report from the container if any
        if (embedContainer.current && window.powerbi) {
          window.powerbi.reset(embedContainer.current);
        }

        const defaultShowNavigation = true;
        setShowNavigation(defaultShowNavigation);
        const defaultShowFilterPane = false;
        setShowFiltersPane(defaultShowFilterPane);
        const defaultShowBookmarksPane = false;
        setShowBookmarksPane(defaultShowBookmarksPane);
        const defaultViewMode = "FitToPage";
        setViewMode(defaultViewMode);

        setEmbedType("ExistingReport");
        setReportType("PowerBiReport");

        const config = {
          type: "report",
          id: ReportId,
          embedUrl: report?.embedUrl,
          accessToken: accessToken,
          tokenType: models.TokenType.Embed,
          viewMode: models.ViewMode.View,
          settings: {
            panes: {
              pageNavigation: {
                visible: defaultShowNavigation,
                position: models.PageNavigationPosition.Left,
              },
              filters: { visible: defaultShowFilterPane, expanded: false },
              bookmarks: { visible: defaultShowBookmarksPane },
            },
            bars: {
              actionBar: { visible: false },
            },
            persistentFiltersEnabled: true,
            personalBookmarksEnabled: true,
          },
        };

        // Embed the report and display it within the div container
        const embeddedReport = window.powerbi.embed(
          embedContainer.current,
          config
        );

        embeddedReport.on("rendered", () => {
          setLoading(false);
        });

        embeddedReport.on("error", () => {
          setLoading(false);
        });

        setEmbeddedReport(embeddedReport);
      } catch (error) {
        console.error("Error embedding report:", error);
        setEmbeddedReport(null);
      } finally {
        setLoading(false);
      }
    },
    [
      report,
      setLoading,
      setShowNavigation,
      setShowFiltersPane,
      setShowBookmarksPane,
      setViewMode,
      setEmbedType,
      setReportType,
    ]
  );
  // Power BI RLS to grenerate token only takes maximum upto 50 roles
  useEffect(() => {
    const generateTokenAndEmbedReport = async () => {
      setEmbeddedReport(null);
      setAccessToken("");

      // Clear existing report from the container if any
      if (embedContainer.current && window.powerbi) {
        window.powerbi.reset(embedContainer.current);
      }

      if (loggedInUser && loggedInUser?.email && report) {
        const formData = {
          reportId: params?.reportId,
          groupId: report?.datasetWorkspaceId,
          accessLevel: "View",
          reportName: report?.name,
          roles:
            loggedInUser?.length > 0 &&
            loggedInUser?.user_principal?.slice(0, 50).map((el) => el),
          datasets: [`${report?.datasetId}`],
          username: loggedInUser?.email,
        };

        try {
          const res = await GENERATEEMBEDTOKEN(formData);
          const newAccessToken = res?.data?.data?.token;
          setAccessToken(newAccessToken);

          if (newAccessToken) {
            // After token is set, embed the report
            embedExistingReport(params?.reportId, newAccessToken);
          }
        } catch (err) {
          console.error("Error generating embed token:", err);
        }
      }
    };

    generateTokenAndEmbedReport();
  }, [
    loggedInUser?.user_principal,
    report,
    params?.reportId,
    embedExistingReport,
    JSON?.stringify(report),
  ]);

  useEffect(() => {
    const generateTokenAndEmbedReport = async () => {
      setEmbeddedReport(null);
      setAccessToken("");

      // Clear existing report from the container if any
      if (embedContainer.current && window.powerbi) {
        window.powerbi.reset(embedContainer.current);
      }

      if (loggedInUser && loggedInUser?.email && report) {
        const formData = {
          reportId: params?.reportId,
          groupId: report?.datasetWorkspaceId,
          accessLevel: "View",
          reportName: report?.name,
          roles: loggedInUser?.user_principal,
          datasets: [`${report?.datasetId}`],
          username: loggedInUser?.email,
        };

        try {
          const res = await GENERATEEMBEDTOKEN(formData);
          const newAccessToken = res?.data?.data?.token;
          setAccessToken(newAccessToken);

          if (newAccessToken) {
            // After token is set, embed the report
            embedExistingReport(params?.reportId, newAccessToken);
          }
        } catch (err) {
          console.error("Error generating embed token:", err);
        }
      }
    };

    generateTokenAndEmbedReport();
  }, [
    loggedInUser?.user_principal,
    report,
    params?.reportId,
    embedExistingReport,
    JSON.stringify(report),
  ]);

  useEffect(() => {
    const generateTokenAndEmbedReport = async () => {
      setEmbeddedReport(null);
      setAccessToken("");
      if (loggedInUser && loggedInUser?.email && report) {
        const formData = {
          reportId: params?.reportId,
          groupId: report?.datasetWorkspaceId,
          accessLevel: "View",
          reportName: report?.name,
          roles: loggedInUser?.user_principal,
          datasets: [`${report?.datasetId}`],
          username: loggedInUser?.email,
        };

        try {
          const res = await GENERATEEMBEDTOKEN(formData);
          const newAccessToken = res?.data?.data?.token;
          setAccessToken(newAccessToken);

          if (newAccessToken) {
            // After token is set, embed the report
            embedExistingReport(params?.reportId, newAccessToken);
          }
        } catch (err) {
          console.error("Error generating embed token:", err);
        }
      }
    };

    generateTokenAndEmbedReport();
  }, [
    loggedInUser?.user_principal,
    report,
    params?.reportId,
    embedExistingReport,
    JSON.stringify(report),
  ]);

  useEffect(() => {
    if (
      accessToken !== "" &&
      accessToken !== undefined &&
      accessToken &&
      params?.reportId &&
      report?.embedUrl
    ) {
      embedExistingReport(params?.reportId, accessToken);
    }
  }, [accessToken, params?.reportId, report?.embedUrl, embedExistingReport]);

  // Embed report function now accepts accessToken explicitly

  // Set height of embed container relative to the height of the window
  const setReportContainerHeight = () => {
    if (embedContainer?.current) {
      var reportContainer = embedContainer.current;
      var reportContainerTopPosition =
        reportType === "PaginatedReport" ? 88 : 122;
      reportContainer.style.height =
        window?.innerHeight - reportContainerTopPosition - 10 + "px";
    }
  };

  useLayoutEffect(() => {
    if (report?.id) {
      setReportContainerHeight();
      window.addEventListener("resize", setReportContainerHeight);
    }
  }, [report?.id, reportType]);

  return (
    <UserLayout>
      <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
        {embedType === "ExistingReport" &&
          reportType === "PowerBiReport" &&
          embeddedReport !== null && (
            <UserReportToolbar
              report={embeddedReport}
              showNavigation={showNavigation}
              setShowNavigation={setShowNavigation}
              showFiltersPane={showFiltersPane}
              setShowFiltersPane={setShowFiltersPane}
              viewMode={viewMode}
              setViewMode={setViewMode}
              showBookmarksPane={showBookmarksPane}
              setShowBookmarksPane={setShowBookmarksPane}
            />
          )}

        <Box ref={embedContainer} sx={{ width: "100%" }} />
      </Box>
      <Spinner isLoading={loading || reportLoading} />
    </UserLayout>
  );
};

export default UserReport;
