import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import OTPInput from "otp-input-react";

import { useForm, Controller } from "react-hook-form";
import {
  osVersion,
  osName,
  mobileVendor,
  browserName,
  fullBrowserVersion,
  mobileModel,
  deviceType,
} from "react-device-detect";
import { yupResolver } from "@hookform/resolvers/yup";
import { OtpVerificationschema } from "../../utils/validationSchema/validation";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyUserOtp } from "../../redux/slice/authSlice";
import Spinner from "../../components/layout/shared/Spinner";
import { RESENDOTP } from "../../services/auth";
import { toast } from "react-toastify";

const defaultValues = {
  otp: null,
};

export default function VerifyMobile() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [resendButtonLoading, setResendButtonLoading] = useState(false);

  const resendVerificationCode = async () => {
    setResendButtonLoading(true);
    await RESENDOTP()
      .then((res) => {
        setResendButtonLoading(false);
      })
      .catch((err) => {
        setResendButtonLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(OtpVerificationschema),
  });

  const dispatch = useDispatch();
  let location = useLocation();

  const { state } = useLocation("/login");
  const { email, role } = state;

  useEffect(() => {
    if (email !== undefined) {
      resendVerificationCode();
    }
  }, [email]);

  if (!state) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const onSubmit = async (data) => {
    setLoading(true);
    const { otp } = data;
     let device = `OS:${osName} + OS_VERSION:${osVersion} + MODEL:${mobileModel} +  VENDOR:${mobileVendor}`;
     let browser = `BROWSER:${browserName} + BW_VERSION:${fullBrowserVersion} + DEVICE_TYPE:${deviceType}`;

    const params = {
      otp: otp,
      device:device,
      browser:browser
    };

    try {
      const dataFromOtp = await dispatch(verifyUserOtp(params));

      if (
        !dataFromOtp?.payload ||
        dataFromOtp?.payload === undefined ||
        dataFromOtp?.payload?.match === false
      ) {
        toast.error("Please enter correct Security code");
        setError(
          "otp",
          "Please enter the correct Security code or else try to resend"
        );
      } else {
        navigate(`/${role.toLowerCase()}/dashboard`);
      }
    } catch (error) {
      // Handle the error here, you can show an error message or log it.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!email || email === "") {
    return <Spinner />;
  }

  return (
    <>
      <section className="login-section" id="sign_up">
        <Container fluid className="" style={{ paddingLeft: "0" }}>
          <Row className="align-items-center">
            <Col md={5} className="p-0">
              <div className="text-center response-image">
                <img
                  src={"/assets/images/white_logo.png"}
                  className="img-fluid"
                  alt="icon"
                />
              </div>
            </Col>
            <Col md="5" className="m-auto">
              <div className="shadow-box">
                <Row className="align-items-center">
                  <Col md={12}>
                    <div className="login-form">
                      {/* <h3>Verify OTP</h3> */}
                      <div className="text-center">
                        <h5>Please verify security code!</h5>
                      </div>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Security code has been sent to : {email}
                          </Form.Label>
                          <Controller
                            control={control}
                            name="otp"
                            render={({ field }) => (
                              <OTPInput
                                value={field.value}
                                onChange={field.onChange}
                                autoFocus
                                className="otp_box justify-content-center"
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                secure
                                name="otp"
                              />
                            )}
                          />

                          {errors.otp && (
                            <Form.Text className="text-danger">
                              {errors.otp.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                        <div>
                          <button
                            className={`login-btn w-100 m-0 ${
                              loading ? "disabled" : ""
                            }`}
                            type="submit" // Change to type="submit" to trigger form submission
                            disabled={loading} // Disable the button while loading
                          >
                            Submit
                          </button>
                        </div>
                        <div className="mt-3">
                          <h6
                            onClick={resendVerificationCode}
                            disabled={resendButtonLoading}
                          >
                            Resend Code
                          </h6>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Spinner isLoading={loading || resendButtonLoading} />
        </Container>
      </section>
    </>
  );
}
