import { Routes, Route, Navigate } from "react-router-dom";
import { ROLES } from "./configs/roles";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import NotFound from "./pages/PageNotFound/NotFound";
import ProtectedRoutes from "./components/routes/ProtectedRoutes";
import PrivacyPolicy from "./pages/shared/PrivacyPolicy";
import TermsAndConditions from "./pages/shared/TermsAndConditions";
import SuperAdminDashboard from "./pages/SuperAdmin/SuperAdminDashboard";
import SuperAdminProfile from "./pages/SuperAdmin/SuperAdminProfile";
import SuperAdminUserManagement from "./pages/SuperAdmin/SuperAdminUserManagement";
import SuperAdminReport from "./pages/SuperAdmin/SuperAdminReport";
import UserOnboard from "./pages/auth/UserOnboard";

import VerifyMobile from "./pages/auth/VerifyMobile";
import UserDashboard from "./pages/Users/UserDashboard";
import UserProfile from "./pages/Users/UserProfile";
import SuperAdminCompanyManagement from "./pages/SuperAdmin/SuperAdminCompanyManagement";
import CompanyAdminDashboard from "./pages/CompanyAdmin/CompanyAdminDashboard";
import CompanyAdminProfile from "./pages/CompanyAdmin/CompanyAdminProfile";
import CompanyAdminUserManagement from "./pages/CompanyAdmin/CompanyAdminUserManagement";
import CompanyAdminReport from "./pages/CompanyAdmin/CompanyAdminReport";
import SuperAdminUpnSettings from "./pages/SuperAdmin/SuperAdminUpnSettings";
import UserReport from "./pages/Users/UserReport";
import ForgotPasswordEmail from "./pages/auth/ForgotPasswordEmail";
import SuperAdminChangePassword from "./pages/SuperAdmin/SuperAdminChangePassword";
import CompanyAdminChangePassword from "./pages/CompanyAdmin/CompanyAdminChangePassword";
import UserChangePassword from "./pages/Users/UserChangePassword";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addActiveMinutesToCurrentUser } from "./redux/slice/authSlice";
import SuperAdminTitleSettings from "./pages/SuperAdmin/SuperAdminTitleSettings";

function App() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);
  const user = localStorage.getItem("user");

  useEffect(() => {
    if (
      loggedInUser?._id !== undefined &&
      loggedInUser?._id !== "" &&
      loggedInUser !== null &&
      user !== undefined &&
      user !== null
    ) {
      const intervalId = setInterval(() => {
        dispatch(addActiveMinutesToCurrentUser());
      }, 60000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [user, loggedInUser]);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify-mobile" element={<VerifyMobile />} />
      <Route path="/forgot-password" element={<ForgotPasswordEmail />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsAndConditions />} /> */}
      <Route path="/user-onboard" element={<UserOnboard />} />

      {/*Protected: Company Admin routes */}
      <Route element={<ProtectedRoutes allowedRoles={[ROLES.COMPANYADMIN]} />}>
        <Route
          path="/companyadmin/dashboard"
          element={<CompanyAdminDashboard />}
        />
        <Route
          path="/companyadmin/change-password/:userId"
          element={<CompanyAdminChangePassword />}
        />
        <Route path="/companyadmin/profile" element={<CompanyAdminProfile />} />
        <Route
          path="/companyadmin/user-management"
          element={<CompanyAdminUserManagement />}
        />

        <Route
          path="/companyadmin/reports/:reportId"
          element={<CompanyAdminReport />}
        />
      </Route>

      {/* Protected: User Route */}
      <Route element={<ProtectedRoutes allowedRoles={[ROLES.USER]} />}>
        <Route path="/user/dashboard" element={<UserDashboard />} />
        <Route path="/user/profile" element={<UserProfile />} />
        <Route
          path="/user/change-password/:userId"
          element={<UserChangePassword />}
        />

        <Route path="/user/reports/:reportId" element={<UserReport />} />
      </Route>

      {/* Protected: SuperAdmin Route */}
      <Route element={<ProtectedRoutes allowedRoles={[ROLES.SUPERADMIN]} />}>
        <Route path="/superadmin/dashboard" element={<SuperAdminDashboard />} />
        <Route
          path="superadmin/change-password/:userId"
          element={<SuperAdminChangePassword />}
        />
        <Route path="/superadmin/profile" element={<SuperAdminProfile />} />

        <Route
          path="/superadmin/company-management"
          element={<SuperAdminCompanyManagement />}
        />
        <Route
          path="/superadmin/user-management"
          element={<SuperAdminUserManagement />}
        />
        <Route
          path="/superadmin/settings/upn"
          element={<SuperAdminUpnSettings />}
        />
        <Route
          path="/superadmin/settings/job-title"
          element={<SuperAdminTitleSettings />}
        />
        <Route
          path="/superadmin/reports/:reportId"
          element={<SuperAdminReport />}
        />
      </Route>
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to={"/404"} />} />
    </Routes>
  );
}

export default App;
