import { toast } from "react-toastify";
import { axiosInstance } from "./axiosInstance";

export const GetReportById = (id) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/report/${id}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETREPORTSBYGROUPID = (groupId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/report/reportsByGroupId/${groupId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETALLREPORTS = (groupId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/report/getReports`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GENERATEEMBEDTOKEN = (formData) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post("/report/getEmbededToken", formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });
