/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  EXPORTUSERLIST,
  GETALLUSERS,
  GETALLUSERSBYCOMPANYID,
  GETCOMPANYADMINCOUNT,
  GETTOPACTIVEUSERSFORCOMPANYADMIN,
  GETTOPACTIVEUSERSFORSUPERADMIN,
  GETTOPREPORTSFORCOMPANYADMIN,
  GETTOPREPORTSFORSUPERADMIN,
  GETUSERBYID,
  GETUSERSANDADMINCOUNT,
  POSTADDUSER,
  POSTDELETEUSER,
  POSTINVITEUSER,
  PUTUSERDATA,
  PostMultipleFileUpload,
} from "../../services/userService";

export const getAllUsers = createAsyncThunk(
  "user/getAllUsers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETALLUSERS(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getAllUsersByCompanyId = createAsyncThunk(
  "user/getAllUsersByCompanyId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETALLUSERSBYCOMPANYID(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUser = createAsyncThunk(
  "user/addUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await POSTADDUSER(userData);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);
export const editUserByUserId = createAsyncThunk(
  "user/editUserByUserId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await PUTUSERDATA(params);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);
export const deleteUserByUserId = createAsyncThunk(
  "user/deleteUserByUserId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await POSTDELETEUSER(params);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const inviteUser = createAsyncThunk(
  "user/inviteUser",
  async (inviteData, { rejectWithValue }) => {
    try {
      const response = await POSTINVITEUSER(inviteData);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const postMultiFileUpload = createAsyncThunk(
  "user/postMultiFileUpload",
  async (params, { rejectWithValue }) => {
    try {
      const response = await PostMultipleFileUpload(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exportUserList = createAsyncThunk(
  "user/getUserList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await EXPORTUSERLIST(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETUSERBYID(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const getUserAndAdminCount = createAsyncThunk(
  "user/getUserAndAdminCount",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETUSERSANDADMINCOUNT(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompanyAdminCount = createAsyncThunk(
  "user/getCompanyAdminCount",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await GETCOMPANYADMINCOUNT(companyId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopActiveUsersForSuperAdmin = createAsyncThunk(
  "user/getTopActiveUsersForSuperAdmin",
  async (count, { rejectWithValue }) => {
    try {
      const response = await GETTOPACTIVEUSERSFORSUPERADMIN(count);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopActiveUsersForCompanyAdmin = createAsyncThunk(
  "user/getTopActiveUsersForCompanyAdmin",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETTOPACTIVEUSERSFORCOMPANYADMIN(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getTopViewedReportsForSuperAdmin = createAsyncThunk(
  "user/getTopViewedReportsForSuperAdmin",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETTOPREPORTSFORSUPERADMIN(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getTopViewedReportsForCompanyAdmin = createAsyncThunk(
  "user/getTopViewedReportsForCompanyAdmin",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETTOPREPORTSFORCOMPANYADMIN(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  userList: [],
  user: null,
  loading: false,
  companyAdminCount: 0,
  usersCount: 0,
  topActiveUsers: [],
  topViewedReports: [],
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.userList = [];
      state.loading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.userList = action.payload?.usersList;
      state.loading = false;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.userList = [];
      state.loading = false;
    });
    builder.addCase(getAllUsersByCompanyId.pending, (state, action) => {
      state.userList = [];
      state.loading = true;
    });
    builder.addCase(getAllUsersByCompanyId.fulfilled, (state, action) => {
      state.userList = action.payload?.usersList;
      state.loading = false;
    });
    builder.addCase(getAllUsersByCompanyId.rejected, (state, action) => {
      state.userList = [];
      state.loading = false;
    });
    builder.addCase(getUserAndAdminCount.pending, (state, action) => {
      state.loading = true;
      state.companyAdminCount = 0;
      state.usersCount = 0;
    });
    builder.addCase(getUserAndAdminCount.fulfilled, (state, action) => {
      state.loading = false;
      state.companyAdminCount = action?.payload?.companyAdmin;
      state.usersCount = action?.payload?.user;
    });
    builder.addCase(getUserAndAdminCount.rejected, (state, action) => {
      state.loading = false;
      state.companyAdminCount = 0;
      state.usersCount = 0;
    });
    builder.addCase(getCompanyAdminCount.pending, (state, action) => {
      state.loading = true;
      state.companyAdminCount = 0;
    });
    builder.addCase(getCompanyAdminCount.fulfilled, (state, action) => {
      state.loading = false;
      state.companyAdminCount = action?.payload?.totalCount;
    });
    builder.addCase(getCompanyAdminCount.rejected, (state, action) => {
      state.loading = false;
      state.companyAdminCount = 0;
    });
    builder.addCase(getTopActiveUsersForSuperAdmin.pending, (state, action) => {
      state.loading = true;
      state.topActiveUsers = 0;
    });
    builder.addCase(
      getTopActiveUsersForSuperAdmin.fulfilled,
      (state, action) => {
        state.loading = false;
        state.topActiveUsers = action?.payload;
      }
    );
    builder.addCase(
      getTopActiveUsersForSuperAdmin.rejected,
      (state, action) => {
        state.loading = false;
        state.topActiveUsers = 0;
      }
    );
    builder.addCase(
      getTopActiveUsersForCompanyAdmin.pending,
      (state, action) => {
        state.loading = true;
        state.topActiveUsers = 0;
      }
    );
    builder.addCase(
      getTopActiveUsersForCompanyAdmin.fulfilled,
      (state, action) => {
        state.loading = false;
        state.topActiveUsers = action?.payload;
      }
    );
    builder.addCase(
      getTopActiveUsersForCompanyAdmin.rejected,
      (state, action) => {
        state.loading = false;
        state.topActiveUsers = 0;
      }
    );
    builder.addCase(
      getTopViewedReportsForSuperAdmin.pending,
      (state, action) => {
        state.loading = true;
        state.topViewedReports = 0;
      }
    );
    builder.addCase(
      getTopViewedReportsForSuperAdmin.fulfilled,
      (state, action) => {
        state.loading = false;
        state.topViewedReports = action?.payload;
      }
    );
    builder.addCase(
      getTopViewedReportsForSuperAdmin.rejected,
      (state, action) => {
        state.loading = false;
        state.topViewedReports = 0;
      }
    );
    builder.addCase(
      getTopViewedReportsForCompanyAdmin.pending,
      (state, action) => {
        state.loading = true;
        state.topViewedReports = 0;
      }
    );
    builder.addCase(
      getTopViewedReportsForCompanyAdmin.fulfilled,
      (state, action) => {
        state.loading = false;
        state.topViewedReports = action?.payload;
      }
    );
    builder.addCase(
      getTopViewedReportsForCompanyAdmin.rejected,
      (state, action) => {
        state.loading = false;
        state.topViewedReports = 0;
      }
    );
    builder.addCase(postMultiFileUpload.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postMultiFileUpload.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(postMultiFileUpload.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.user = action?.payload && action?.payload;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.user = action?.payload && action?.payload?.data;
    });
    builder.addCase(editUserByUserId.fulfilled, (state, action) => {
      state.user = action?.payload && action?.payload?.data;
    });
  },
});

export default userSlice.reducer;
