/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  DELETEJOBTITLEDATA,
  GETALLJOBTITLE,
  GETJOBTITLEBYCOMPANYID,
  GETJOBTITLEBYID,
  POSTADDJOBTITLE,
  PUTJOBTITLEDATA,
} from "../../services/jobTitleService";

export const getAllJobTitles = createAsyncThunk(
  "jobTitle/getAllJobTitles",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETALLJOBTITLE(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addJobTitle = createAsyncThunk(
  "jobTitle/addJobTitle",
  async (jobTitleData, { rejectWithValue }) => {
    try {
      const response = await POSTADDJOBTITLE(jobTitleData);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const editJobTitleById = createAsyncThunk(
  "jobTitle/editJobTitleById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await PUTJOBTITLEDATA(params);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const getJobTitlesByCompanyId = createAsyncThunk(
  "jobTitle/getJobTitlesByCompanyId",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await GETJOBTITLEBYCOMPANYID(companyId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getJobTitleById = createAsyncThunk(
  "jobTitle/getJobTitleById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETJOBTITLEBYID(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);
export const deleteJobTitleById = createAsyncThunk(
  "jobTitle/deleteJobTitleById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await DELETEJOBTITLEDATA(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

const initialState = {
  jobTitleData: null,
  jobTitlesList: [],
  jobTitleListForSuperAdmin: [],
  jobTitleLoading: false,
};

const jobTitleSlice = createSlice({
  name: "jobTitle",
  initialState: initialState,

  extraReducers: (builder) => {
    builder.addCase(addJobTitle.fulfilled, (state, action) => {
      state.jobTitleData = action?.payload && action?.payload?.data;
    });
    builder.addCase(getJobTitleById.fulfilled, (state, action) => {
      state.jobTitleData = action?.payload && action?.payload;
    });
    builder.addCase(getAllJobTitles.pending, (state, action) => {
      state.jobTitleLoading = true;
      state.jobTitlesList = [];
    });

    builder.addCase(getAllJobTitles.fulfilled, (state, action) => {
      state.jobTitleLoading = false;
      state.jobTitlesList = action.payload?.data?.value;
    });
    builder.addCase(getAllJobTitles.rejected, (state, action) => {
      state.jobTitleLoading = false;
      state.jobTitlesList = [];
    });
    builder.addCase(getJobTitlesByCompanyId.fulfilled, (state, action) => {
      state.jobTitlesList = action?.payload;
    });
    builder.addCase(deleteJobTitleById.fulfilled, (state, action) => {
      state.jobTitleData = action?.payload;
    });
  },
});

export default jobTitleSlice.reducer;
