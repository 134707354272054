import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GoPasskeyFill } from "react-icons/go";
import { FaUsers } from "react-icons/fa";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyList } from "../../redux/slice/companySlice";
import { getReportByGroupId } from "../../redux/slice/reportSlice";
import { BiSolidReport } from "react-icons/bi";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { FaUsersCog } from "react-icons/fa";
import SuperAdminSidebarMenu from "./SuperAdminSidebarMenu";

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? "did-floating-label did-floating-label-top"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

const colourStylesState = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxShadow: "none",
    padding: "2px 10px",
    border: "none",
    outline: "none",
    background: "transparent",
    color: "#fff",

    "&:focus": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
    "&:active": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
    "&:hover": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 12,
    color: "#fff",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 200, // set the z-index to a high value
    color: "#fff",
  }),
  menuList: (base) => ({
    ...base,
    height: "auto",
    backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
    "::-webkit-scrollbar": {
      width: "9px",
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
    },
    "::-webkit-scrollbar-track": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&:focus": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)",
      backgroundColor: "#fff",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&:active": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
      backgroundColor: "#fff",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#fff",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
  }),
};

const SuperAdminSidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const dispatch = useDispatch();

  const reportsListFromReducer = useSelector(({ report }) => report.reportList);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [route, setRoute] = useState([
    {
      path: "/superadmin/dashboard",
      name: "Dashboard",
      icon: (
        <div>
          <img
            src={"/assets/images/dashboard-grey.svg"}
            width="20px"
            height="20px"
            style={{ maxHeight: "70px" }}
            alt="dashboard icon"
            className="logo-grey"
          />
          <img
            src={"/assets/images/dashboard-white.svg"}
            width="20px"
            height="20px"
            style={{ maxHeight: "70px" }}
            alt="dashboard icon"
            className="logo-white"
          />
        </div>
      ),
    },
    {
      path: "/superadmin/company-management",
      name: "Companies",
      icon: (
        <i
          width="20px"
          height="20px"
          className="fa fa-sitemap"
          aria-hidden="true"
        ></i>
      ),
    },
    {
      path: "/superadmin/user-management",
      name: "Users",
      icon: <FaUsers width="20px" height="20px" />,
    },
    // {
    //   path: "/superadmin/reports-list",
    //   name: "Reports",
    //   icon: (
    //     <i
    //       width="20px"
    //       height="20px"
    //       className="fa fa-file"
    //       aria-hidden="true"
    //       style={{ marginLeft: "2px" }}
    //     ></i>
    //   ),
    // },
    {
      path: "/superadmin/settings",
      name: "Settings",
      icon: (
        <div>
          <img
            width="20px"
            height="20px"
            src={"/assets/images/settings-white.svg"}
            style={{ maxHeight: "70px" }}
            alt="Treatment Notes icon"
            className="logo-white"
          />
        </div>
      ),
      subRoutes: [
        {
          path: "/superadmin/settings/upn",
          name: "UPN Settings",
          icon: <GoPasskeyFill width="20px" height="20px" />,
        },
        {
          path: "/superadmin/settings/job-title",
          name: "Job Title Settings",
          icon: <FaUsersCog width="20px" height="20px" />,
        },
      ],
    },
  ]);

  /*   const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );
  useEffect(() => {
    setCompanyLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (companyListFromReducer?.length) {
      setCompanyOptions(companyListFromReducer);
      setSelectedCompany(companyListFromReducer[0]);
    }
  }, [dispatch, companyListFromReducer]);

  useEffect(() => {
    if (
      selectedCompany?.id !== undefined &&
      selectedCompany?.id !== "" &&
      companyListFromReducer?.length
    ) {
      dispatch(getReportByGroupId(selectedCompany?.id));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (reportsListFromReducer) {
      const updatedData = reportsListFromReducer?.map((report, index) => {
        return {
          path: `/superadmin/reports/${report?.id}`,
          name: report?.name,
          icon: <BiSolidReport />,
          report: report,
        };
      });

      setRoute((old) => {
        return old.map((el) => {
          if (el?.name === "Reports") {
            return {
              ...el, // Keep existing properties
              subRoutes: updatedData, // Update subRoutes property
            };
          }
          return el; // Return other elements unchanged
        });
      });
    }
  }, [reportsListFromReducer, selectedCompany]); */

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
      <div
        style={{
          width: isOpen ? "270px" : "76px",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <div
            style={{ marginLeft: isOpen ? "50px" : "0px", cursor: "pointer" }}
            className="bars"
          >
            {isOpen ? (
              <BsFillArrowLeftCircleFill onClick={toggle} />
            ) : (
              <BsFillArrowRightCircleFill onClick={toggle} />
            )}
          </div>
        </div>
        <div className="sidebar-scroll">
          {route?.length &&
            route?.map((route, index) => {
              if (route?.name === "Reports") {
                return (
                  <div key={route?.name}>
                    <div
                      className="dropdown-company linear-grad"
                      style={{
                        display: isOpen ? "block" : "none",
                        fontFamily: "Segoe UI",
                      }}
                    >
                      {/* <div>
                      <div className="icon">{route?.icon}</div>
                    </div> */}

                      <div>
                        <Select
                          className=""
                          options={companyOptions}
                          onChange={(e) => {
                            setSelectedCompany(e);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder={" "}
                          disabled={companyLoading}
                          value={selectedCompany}
                          styles={colourStylesState}
                          components={{
                            Control: Control,
                            // DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                    <SuperAdminSidebarMenu
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                      route={route}
                      key={route?.name}
                      setIsOpen={setIsOpen}
                    />
                  </div>
                );
              }
              if (route?.subRoutes) {
                return (
                  <SuperAdminSidebarMenu
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    route={route}
                    key={route?.name}
                    setIsOpen={setIsOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route?.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  <div className="icon">{route?.icon}</div>
                  <div
                    style={{
                      display: isOpen ? "block" : "none",
                      fontFamily: "Segoe UI",
                    }}
                    className="link_text"
                  >
                    {route?.name}
                  </div>
                </NavLink>
              );
            })}
        </div>
      </div>
      
  );
};

export default SuperAdminSidebar;
