import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const CompanyAdminSidebarMenu = ({ route, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);

  const filteredSubRoutes = route?.subRoutes
    ?.filter((subRoute) =>
      subRoute.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name)); // Sort subRoutes alphabetically

  return (
    <>
      <div className="menu" onClick={toggleMenu}>
        <div className="menu_item">
          <div className="icon">{route?.icon}</div>
          {isOpen && (
            <div className="link_text" style={{ cursor: "pointer" }}>
              {route?.name}
            </div>
          )}
        </div>
        {isOpen && (
          <>
            {isMenuOpen ? (
              <div>
                <FaAngleUp size={"16px"} />
              </div>
            ) : (
              <div>
                <FaAngleDown size={"16px"} />
              </div>
            )}
          </>
        )}
      </div>
      {isMenuOpen && isOpen && (
        <div className="menu_container company_menu_container">
          <input
            style={{
              padding: "2px",
              borderRadius: "8px",
              borderColor: "transparent",
              outline: "none",
              margin: "6px 4px",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-bar input-sidebar"
          />
          {filteredSubRoutes.map((subRoute, i) => (
            <div key={i}>
              <NavLink
                to={subRoute?.path}
                className="link"
                state={{ report: subRoute?.report }}
              >
                <div className="link_text">{subRoute?.name}</div>
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CompanyAdminSidebarMenu;
