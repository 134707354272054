import React from "react";

import SuperAdminLayout from "../../components/SuperAdminLayout/SuperAdminLayout";
import { Col, Container, Row } from "react-bootstrap";
import UserListForSuperAdmin from "./UserListForSuperAdmin";

const SuperAdminUserManagement = () => {
  return (
    <SuperAdminLayout>
      <section className="outer-dash-section">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="box-shadow-set">
                <UserListForSuperAdmin />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="space-bottom">.</div>
      </section>
    </SuperAdminLayout>
  );
};

export default SuperAdminUserManagement;
