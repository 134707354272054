import axios from "axios";
import {
  REACT_APP_BACKEND_URL,
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../configs/config";
import { logout } from "../redux/slice/authSlice";
import CryptoJS from "crypto-js";

export const axiosInstance = axios.create({
  baseURL: REACT_APP_BACKEND_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  config.headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  if (token) config.headers.Authorization = `${token}`;

  return config;
});

// Add a response interceptor || Middleware for 401
axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      logout();
      setTimeout(() => {
        window.location.replace("/login");
      }, 500);
    } else {
      return response;
    }
  },
  (error) => {
    const {
      response: { status },
    } = error;

    if (status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      logout();
      setTimeout(() => {
        window.location.replace("/login");
      }, 500);
    } else {
      return Promise.reject(error);
    }
  }
);
