import * as yup from "yup";

// login schema
const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
  password: yup.string().trim().required("Password is required"),
  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .trim()
  //   .matches(
  //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //     "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
  //   ),
});
const SuperAdminAddUserSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .email("Invalid email"),
    company: yup.object().shape({
      name: yup.string().trim().required("Please select Company Name"),
      id: yup.string().trim().required("Please Enter Company Name"),
    }),
    job_title: yup.object().shape({
      label: yup.string().trim().required("Please select Job Title"),
      value: yup.string().trim().required("Please Enter Job Title"),
    }),
    role: yup.object().shape({
      label: yup.string().trim().required("Please select User Type"),
      value: yup.string().trim().required("Please Enter User Type"),
    }),
    // user_principal: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       label: yup
    //         .string()
    //         .trim()
    //         .required("Please select UserPrincipal Name"),
    //       value: yup
    //         .string()
    //         .trim()
    //         .required("Please Enter UserPrincipal Name"),
    //     })
    //   )
    //   .min(1, "At least one Role is required"),
    zipcode: yup.string().when("zipcode", {
      is: (exist) => !!exist,
      then: () =>
        yup
          .string()
          .trim()
          .test("len", "Enter valid zipcode", (val) => val?.length === 5)
          .matches(/^[0-9]+$/, "Enter valid zipcode"),
      otherwise: () => yup.string(),
    }),
    terms: yup
      .bool()
      .oneOf([true], "You must accept the privacy policy & terms"),
  },
  [["zipcode", "zipcode"]]
);

const SuperAdminEditUserSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .email("Invalid email"),

    company: yup.object().shape({
      name: yup.string().trim().required("Please select Company Name"),
      id: yup.string().trim().required("Please Enter Company Name"),
    }),
    job_title: yup.object().shape({
      label: yup.string().trim().required("Please select Job Title"),
      value: yup.string().trim().required("Please Enter Job Title"),
    }),
    role: yup.object().shape({
      label: yup.string().trim().required("Please select User Type"),
      value: yup.string().trim().required("Please Enter User Type"),
    }),
    // user_principal: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       label: yup
    //         .string()
    //         .trim()
    //         .required("Please select UserPrincipal Name"),
    //       value: yup
    //         .string()
    //         .trim()
    //         .required("Please Enter UserPrincipal Name"),
    //     })
    //   )
    //   .min(1, "At least one Role is required"),
    zipcode: yup.string().when("zipcode", {
      is: (exist) => !!exist,
      then: () =>
        yup
          .string()
          .trim()
          .test("len", "Enter valid zipcode", (val) => val?.length === 5)
          .matches(/^[0-9]+$/, "Enter valid zipcode"),
      otherwise: () => yup.string(),
    }),
    terms: yup
      .bool()
      .oneOf([true], "You must accept the privacy policy & terms"),
  },
  [["zipcode", "zipcode"]]
);

const CompanyAdminAddUserSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .email("Invalid email"),
    job_title: yup.object().shape({
      label: yup.string().trim().required("Please select Job Title"),
      value: yup.string().trim().required("Please Enter Job Title"),
    }),
    role: yup.object().shape({
      label: yup.string().trim().required("Please select User Type"),
      value: yup.string().trim().required("Please Enter User Type"),
    }),
    // company: yup.object().shape({
    //   name: yup.string().trim().required("Please select Company Name"),
    //   id: yup.string().trim().required("Please Enter Company Name"),
    // }),

    // user_principal: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       label: yup
    //         .string()
    //         .trim()
    //         .required("Please select UserPrincipal Name"),
    //       value: yup
    //         .string()
    //         .trim()
    //         .required("Please Enter UserPrincipal Name"),
    //     })
    //   )
    //   .min(1, "At least one Role is required"),
    terms: yup
      .bool()
      .oneOf([true], "You must accept the privacy policy & terms"),
    zipcode: yup.string().when("zipcode", {
      is: (exist) => !!exist,
      then: () =>
        yup
          .string()
          .trim()
          .test("len", "Enter valid zipcode", (val) => val?.length === 5)
          .matches(/^[0-9]+$/, "Enter valid zipcode"),
      otherwise: () => yup.string(),
    }),
  },
  [["zipcode", "zipcode"]]
);

const CompanyAdminEditUserSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .email("Invalid email"),

    job_title: yup.object().shape({
      label: yup.string().trim().required("Please select Job Title"),
      value: yup.string().trim().required("Please Enter Job Title"),
    }),
    // company: yup.object().shape({
    //   name: yup.string().trim().required("Please select Company Name"),
    //   id: yup.string().trim().required("Please Enter Company Name"),
    // }),
    role: yup.object().shape({
      label: yup.string().trim().required("Please select User Type"),
      value: yup.string().trim().required("Please Enter User Type"),
    }),
    // user_principal: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       label: yup
    //         .string()
    //         .trim()
    //         .required("Please select UserPrincipal Name"),
    //       value: yup
    //         .string()
    //         .trim()
    //         .required("Please Enter UserPrincipal Name"),
    //     })
    //   )
    //   .min(1, "At least one Role is required"),
    zipcode: yup.string().when("zipcode", {
      is: (exist) => !!exist,
      then: () =>
        yup
          .string()
          .trim()
          .test("len", "Enter valid zipcode", (val) => val?.length === 5)
          .matches(/^[0-9]+$/, "Enter valid zipcode"),
      otherwise: () => yup.string(),
    }),

    terms: yup
      .bool()
      .oneOf([true], "You must accept the privacy policy & terms"),
  },
  [["zipcode", "zipcode"]]
);

const changePasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .required("Password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirm_password: yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref("new_password"), null], "Both password should match")
    .required("Please type password again"),
});

const resetPasswordSchema = yup.object().shape({
  old_password: yup.string().required("Old Password is required"),
  new_password: yup
    .string()
    .required("Password is required")
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirm_password: yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref("new_password"), null], "Both password should match")
    .required("Please type password again"),
});

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
});

const OtpVerificationschema = yup.object().shape({
  otp: yup
    .string()
    .trim()
    .required("Security code is required")
    .max(6, "Enter valid Security code")
    .test("len", "Enter valid Security code", (val) => val?.length === 6),
});

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
});

const companyInfoInfoSchema = yup.object().shape({
  name: yup.string().trim().required("company name is required"),
});

const AdminInfoInfoSchema = yup.object().shape({
  organizationName: yup.string().trim().required("company name is required"),
  organizationType: yup.string().trim().required("company type is required"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
});

const SuperAdminInviteSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
  company: yup.object().shape({
    name: yup.string().trim().required("Please select Company Name"),
    id: yup.string().trim().required("Please Enter Company Name"),
  }),
  job_title: yup.object().shape({
    label: yup.string().trim().required("Please select Job Title"),
    value: yup.string().trim().required("Please Enter Job Title"),
  }),
  role: yup.object().shape({
    label: yup.string().trim().required("Please select User Type"),
    value: yup.string().trim().required("Please Enter User Type"),
  }),

  user_principal: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().trim().required("Please select UserPrincipal Name"),
        value: yup.string().trim().required("Please Enter UserPrincipal Name"),
      })
    )
    .min(1, "At least one Role is required"),
});
const CompanyAdminInviteSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
  job_title: yup.object().shape({
    label: yup.string().trim().required("Please select Job Title"),
    value: yup.string().trim().required("Please Enter Job Title"),
  }),
  role: yup.object().shape({
    label: yup.string().trim().required("Please select User Type"),
    value: yup.string().trim().required("Please Enter User Type"),
  }),
  // company: yup.object().shape({
  //   name: yup.string().trim().required("Please select Company Name"),
  //   id: yup.string().trim().required("Please Enter Company Name"),
  // }),

  user_principal: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().trim().required("Please select UserPrincipal Name"),
        value: yup.string().trim().required("Please Enter UserPrincipal Name"),
      })
    )
    .min(1, "At least one Role is required"),
});

const importUserSchema = yup.object().shape({
  // file: yup.mixed().required("File is required"),
});

const userOnboardSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[a-zA-Z\s.]+$/, {
        message: "Only alphabets and spaces are allowed",
        excludeEmptyString: true,
      }),

    terms: yup
      .bool()
      .oneOf([true], "You must accept the privacy policy & terms"),
    new_password: yup
      .string()
      .required("Password is required")
      .trim()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    confirm_password: yup
      .string()
      .trim()
      .required("Confirm password is required")
      .oneOf([yup.ref("new_password"), null], "Both password should match")
      .required("Please type password again"),
    zipcode: yup.string().when("zipcode", {
      is: (exist) => !!exist,
      then: () =>
        yup
          .string()
          .trim()
          .test("len", "Enter valid zipcode", (val) => val?.length === 5)
          .matches(/^[0-9]+$/, "Enter valid zipcode"),
      otherwise: () => yup.string(),
    }),
  },
  [["zipcode", "zipcode"]]
);

const editProfileSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  lastName: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  terms: yup.bool().oneOf([true], "You must accept the privacy policy & terms"),
});

const addUpnSchema = yup.object().shape({
  user_principal: yup.string().trim().required("Please enter Role"),
  terms: yup.bool().oneOf([true], "You must accept the privacy policy & terms"),
});

const editUpnSchema = yup.object().shape({
  user_principal: yup.string().trim().required("Please enter Role"),
  terms: yup.bool().oneOf([true], "You must accept the privacy policy & terms"),
});
const addJobTitleSchema = yup.object().shape({
  job_title: yup.string().trim().required("Please enter Role"),
  terms: yup.bool().oneOf([true], "You must accept the privacy policy & terms"),
});

const editJobTitleSchema = yup.object().shape({
  job_title: yup.string().trim().required("Please enter Role"),
  terms: yup.bool().oneOf([true], "You must accept the privacy policy & terms"),
});

export {
  loginSchema,
  resetPasswordSchema,
  forgotPasswordSchema,
  SuperAdminAddUserSchema,
  SuperAdminEditUserSchema,
  OtpVerificationschema,
  emailSchema,
  changePasswordSchema,
  AdminInfoInfoSchema,
  companyInfoInfoSchema,
  SuperAdminInviteSchema,
  CompanyAdminInviteSchema,
  importUserSchema,
  userOnboardSchema,
  editProfileSchema,
  addUpnSchema,
  editUpnSchema,
  addJobTitleSchema,
  editJobTitleSchema,
  CompanyAdminAddUserSchema,
  CompanyAdminEditUserSchema,
};
