import React from "react";

import SuperAdminLayout from "../../components/SuperAdminLayout/SuperAdminLayout";

import { Col, Container, Row } from "react-bootstrap";
import PowerBiUpnList from "./PowerBiUpnList";

const SuperAdminUpnSettings = () => {
  return (
    <SuperAdminLayout>
      <section className="outer-dash-section">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="box-shadow-set">
                <PowerBiUpnList />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="space-bottom">.</div>
      </section>
    </SuperAdminLayout>
  );
};

export default SuperAdminUpnSettings;
