import React from "react";
import DashHeader from "./DashHeader";
import UserSidebar from "./UserSidebar";
import { Helmet } from "react-helmet";
import Footer from "./Footer";

function UserLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Client Access - SNF InFocus</title>
      </Helmet>
      <div className="dashboard-layout">
        <div className="inner-dash-bpx p-0 m-0">
          <DashHeader />
          <div className="dashboard-main-sec">
            <UserSidebar></UserSidebar>
            {props.children}
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserLayout;
