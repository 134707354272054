import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { Col, Container, Form, Row } from "react-bootstrap";
import { emailSchema } from "../../utils/validationSchema/validation";
import { useState } from "react";
import InputField from "../../components/layout/shared/InputField";
import { forgotPasswordTriggerEmail } from "../../redux/slice/authSlice";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../components/layout/shared/Spinner";

const defaultValues = {
  email: "",
};

const ForgotPasswordEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    handleBlur,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(emailSchema),
  });

  const onSubmit = async (e) => {
    const { email } = e;

    const params = {
      email: email,
    };

    setLoading(true);

    try {
      const res = await dispatch(forgotPasswordTriggerEmail(params?.email));

      if (
        res?.payload?.accepted?.length &&
        res?.payload?.rejected?.length === 0
      ) {
        toast.success("Email has been sent successfully");
        navigate("/", { replace: true });
      } else if (res?.payload?.rejected?.length) {
        toast.error("Something went wrong");
      }
    } catch (err) {
      // Handle specific error cases, if needed.
      console.error("An error occurred:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-section">
        <Container>
          <Row className="align-items-center">
            <Col md="6" className="m-auto">
              <div className="shadow-box">
                <Row className="align-items-center">
                  <Col md={12}>
                    <div className="login-form">
                      <h3>Forgot Password? 🔒</h3>
                      <h5>
                        Please enter your email, and we&prime;ll send you
                        instructions to reset your password
                      </h5>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group
                          className="did-floating-label-content mb-3"
                          controlId="formBasicEmail"
                        >
                          <Controller
                            control={control}
                            name="email"
                            render={({ field }) => (
                              <InputField
                                className={"did-floating-input"}
                                classNameLabel={
                                  "did-floating-label did-floating-labe-astrisk"
                                }
                                type="email"
                                name="email"
                                label={"Email Address"}
                                required={true}
                                value={field?.value}
                                onChange={field.onChange}
                                onBlur={handleBlur}
                              />
                            )}
                          />

                          {errors.email && (
                            <Form.Text className="text-danger">
                              {errors.email.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                        <div>
                          <button
                            className={`login-btn w-100 m-0 ${
                              loading ? "disabled" : ""
                            }`}
                            type="submit" // Change to type="submit" to trigger form submission
                            disabled={loading} // Disable the button while loading
                          >
                            Send Request
                          </button>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-3">
                          <div className="login-redirect-link">
                            <a
                              target="_blank"
                              href="https://snf-infocus.com"
                              rel="noreferrer"
                            >
                              SNF InFocus
                            </a>
                            {" | "}
                            <a
                              href="https://snf-infocus.com/privacy-policy"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                          </div>
                          <Link to="/">Return to Login</Link>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Spinner isLoading={loading} />
      </div>
    </>
  );
};

export default ForgotPasswordEmail;
