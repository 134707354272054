export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

export const SuperAdminRoleOptions = [
  { label: "USER", value: "USER" },
  { label: "COMPANY ADMIN", value: "COMPANYADMIN" },
];
export const CompanyAdminRoleOptions = [{ label: "USER", value: "USER" }];

export const jobTitleOptions = [
  { label: "OPS", value: "OPS" },
  { label: "FINANCE AND REVENUE", value: "FINANCE AND REVENUE" },
  { label: "CLINICAL", value: "CLINICAL" },
  { label: "MARKETING", value: "MARKETING" },
];

export const companyOptions = [
  { label: "Sandstone", value: "53b5d474-5ff9-413f-8a47-b3fb28fad4f7" },
  { label: "Company2", value: "53b5d474-5ff9-413f-8a47-b3fb28fad4f7" },
  { label: "Company3", value: "53b5d474-5ff9-413f-8a47-b3fb28fad4f7" },
];
