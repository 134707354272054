import { toast } from "react-toastify";
import { axiosInstance } from "./axiosInstance";

export const GETALLCOMPANIES = (companyId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/report/getGroups`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETCOMPANYBYID = (companyId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/report/getCompanyById/${companyId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const POSTADDCOMPANY = (companyData) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post("report/addGroup", companyData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });

export const PUTCOMPANYDATA = (params) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .post(`report/updateGroup`, params?.formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });
