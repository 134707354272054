import React from "react";

const Footer = () => {
  return (
    <div className="footer-bottom">
      <a href="https://snf-infocus.com/privacy-policy" target="_blank" rel="noreferrer">
        Privacy Policy - SNF InFocus
      </a>
    </div>
  );
};

export default Footer;
