import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { resetPasswordSchema } from "../../utils/validationSchema/validation";
import { Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { changePassword, logout } from "../../redux/slice/authSlice";
import Spinner from "../../components/layout/shared/Spinner";
import SuperAdminLayout from "../../components/SuperAdminLayout/SuperAdminLayout";
import { useNavigate } from "react-router-dom";

const CompanyAdminChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  // ** States
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const toggleShowOldPassword = () => {
    setShowOldPassword((prev) => !prev);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmNewPassword((prev) => !prev);
  };

  const defaultValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  // ** Hooks
  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(resetPasswordSchema),
  });

  const { control, handleSubmit, formState } = methods;
  const { errors } = formState;

  useEffect(() => {
    if (!loggedInUser?.email) {
      navigate("/login");
    }
  }, [loggedInUser]);

  const onSubmit = async (data) => {
    const params = {
      oldPassword: data?.old_password,
      email: loggedInUser?.email,
      newPassword: data?.confirm_password,
    };

    // Set loading to true when the login request starts
    setLoading(true);

    try {
      const userData = await dispatch(changePassword(params));

      if (
        !userData?.payload ||
        userData?.payload === undefined ||
        userData?.payload?.data?.match === false
      ) {
        toast.error("Please enter correct credentials");
        return;
      } else {
        toast.success(
          "Password changed successfully, Please log in with new credentials."
        );
        dispatch(logout());
      }
    } catch (error) {
      // Handle errors here
      console.error("Login error:", error);
      toast.error("An error occurred during login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SuperAdminLayout>
      <section className="outer-dash-section">
        <Container>
          <Row>
            <Col lg={8}>
              <div className="message-mail-box mt-3">
                <Container>
                  <Row className="align-items-center">
                    <Col md="12" className="m-auto">
                      <div className="">
                        <Row className="align-items-center">
                          <Col md={12}>
                            <div className="login-form">
                              <div className="d-flex align-items-center justify-content-between">
                                <h3 className="dash-common-heading">
                                  Change Password
                                </h3>
                              </div>
                              <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="old_password"
                                >
                                  <Controller
                                    control={control}
                                    name="old_password"
                                    render={({ field }) => (
                                      <input
                                        className="did-floating-input"
                                        placeholder=" "
                                        type={
                                          showOldPassword ? "text" : "password"
                                        }
                                        value={field?.value}
                                        onChange={field?.onChange}
                                      />
                                    )}
                                  />
                                  <label className="did-floating-label">
                                    Old Password*
                                  </label>
                                  {showOldPassword ? (
                                    <span
                                      onClick={toggleShowOldPassword}
                                      id="#password-field"
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      className="fa fa-fw fa-eye field-icon toggle-password"
                                    ></span>
                                  ) : (
                                    <span
                                      onClick={toggleShowOldPassword}
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      id="#password-field"
                                      className="fa fa-fw fa-eye-slash field-icon toggle-password"
                                    ></span>
                                  )}
                                  {errors.old_password && (
                                    <Form.Text className="text-danger">
                                      {errors.old_password.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="new_password"
                                >
                                  <Controller
                                    control={control}
                                    name="new_password"
                                    render={({ field }) => (
                                      <input
                                        className="did-floating-input"
                                        placeholder=" "
                                        type={
                                          showNewPassword ? "text" : "password"
                                        }
                                        value={field?.value}
                                        onChange={field?.onChange}
                                      />
                                    )}
                                  />
                                  <label className="did-floating-label">
                                    New Password*
                                  </label>
                                  {showNewPassword ? (
                                    <span
                                      onClick={toggleShowNewPassword}
                                      id="#password-field"
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      className="fa fa-fw fa-eye field-icon toggle-password"
                                    ></span>
                                  ) : (
                                    <span
                                      onClick={toggleShowNewPassword}
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      id="#password-field"
                                      className="fa fa-fw fa-eye-slash field-icon toggle-password"
                                    ></span>
                                  )}
                                  {errors.new_password && (
                                    <Form.Text className="text-danger">
                                      {errors.new_password.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="confirm_password"
                                >
                                  <Controller
                                    control={control}
                                    name="confirm_password"
                                    render={({ field }) => (
                                      <input
                                        className="did-floating-input"
                                        placeholder=" "
                                        type={
                                          showConfirmNewPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={field?.value}
                                        onChange={field?.onChange}
                                      />
                                    )}
                                  />
                                  <label className="did-floating-label">
                                    Confirm New Password*
                                  </label>
                                  {showConfirmNewPassword ? (
                                    <span
                                      onClick={toggleShowConfirmPassword}
                                      id="#password-field"
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      className="fa fa-fw fa-eye field-icon toggle-password"
                                    ></span>
                                  ) : (
                                    <span
                                      onClick={toggleShowConfirmPassword}
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      id="#password-field"
                                      className="fa fa-fw fa-eye-slash field-icon toggle-password"
                                    ></span>
                                  )}
                                  {errors.confirm_password && (
                                    <Form.Text className="text-danger">
                                      {errors.confirm_password.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                                <div>
                                  <button
                                    className={`login-btn w-100 m-0 ${
                                      loading ? "disabled" : ""
                                    }`}
                                    type="submit" // Change to type="submit" to trigger form submission
                                    disabled={loading} // Disable the button while loading
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="space-bottom">.</div>
      </section>
      <Spinner isLoading={loading} />
    </SuperAdminLayout>
  );
};

export default CompanyAdminChangePassword;
