import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const SuperAdminSidebarMenu = ({ route, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div className="menu" onClick={toggleMenu}>
        <div className="menu_item">
          <div className="icon">{route?.icon}</div>

          {isOpen && (
            <div className="link_text" style={{ cursor: "pointer" }}>
              {route?.name}
            </div>
          )}
        </div>

        {isOpen && (
          <>
            {isMenuOpen ? (
              <div>
                <FaAngleUp size={"16px"} />
              </div>
            ) : (
              <div>
                <FaAngleDown size={"16px"} />
              </div>
            )}
          </>
        )}
      </div>{" "}
      {isMenuOpen && (
        <div className="menu_container admin_menu_container">
          {route?.subRoutes?.map((subRoute, i) => {
            return (
              <div key={i} custom={i}>
                <NavLink
                  to={subRoute?.path}
                  className="link"
                  state={{ report: subRoute?.report }}
                >
                  <div className="icon">{subRoute?.icon}</div>
                  <div className="link_text">{subRoute?.name}</div>
                </NavLink>
              </div>
            );
          })}
        </div>
      )}{" "}
    </>
  );
};

export default SuperAdminSidebarMenu;
