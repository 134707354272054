import { useState } from "react";

import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import { yupResolver } from "@hookform/resolvers/yup";

import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { importUserSchema } from "../../utils/validationSchema/validation";

import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, postMultiFileUpload } from "../../redux/slice/userSlice";
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle, Grid, IconButton } from "@mui/material";
import { BsCloudUpload } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import Spinner from "../../components/layout/shared/Spinner";
import { toast } from "react-toastify";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../configs/config";

const defaultValues = {
  file: "",
};

const SuperAdminImportUsersForm = (props) => {
  // ** States
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);
  const pageLoading = useSelector(({ user }) => user.loading);

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: true,

    accept: {
      "text/csv": [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles?.map((file) => Object.assign(file)));
    },
  });

  const handleDownload = () => {
    setLoading(true);
    // Construct the file path to your CSV file in the public folder
    const filePath = "/assets/files/sample.csv";

    // Create a temporary URL for the file
    const fileURL = process.env.PUBLIC_URL + filePath;

    // Create an anchor element and set its href to the file URL
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = "sample.csv"; // Set the desired file name

    // Programmatically click the anchor to trigger the download
    a.click();
    setLoading(false);
  };

  const acceptedFileItems = file.map((file) => (
    <div key={file.path} style={{ display: "flex", alignItems: "center" }}>
      <span
        style={{
          width: "100%",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {file.path} - {file.size} bytes
      </span>
      <IconButton onClick={() => handleRemoveFile(file)} color="error">
        <BsFillTrashFill />
      </IconButton>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <>
        <div key={file.path} style={{ marginBottom: "5px" }}>
          {file.path} - {file.size} bytes
          {errors.map((e) => (
            <strong
              key={e.code}
              style={{ paddingTop: "5px", width: "100%", display: "block" }}
            >
              Note: {e.message}
            </strong>
          ))}
        </div>
      </>
    );
  });

  const handleRemoveFile = (e) => {
    const uploadedFiles = file;
    const filtered = uploadedFiles.filter((i) => i.name !== e.name);
    setFile([...filtered]);
  };

  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(importUserSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    if (!file?.length) {
      setError(
        "file",
        { type: "custom", message: "Please upload a valid document" },
        {
          shouldValidate: true,
        }
      );
      setLoading(false);
      return;
    }

    const UserForm = { csv: file?.[0] };

    const encryptedToken = localStorage.getItem("accessToken");
    const token =
      encryptedToken &&
      CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
        iv: REACT_APP_CRYPTOJS_VECTOR,
      }).toString(CryptoJS.enc.Utf8);

    const params = { UserForm, accessToken: token };

    dispatch(postMultiFileUpload(params))
      .then((res) => {
        if (res?.payload?.success !== undefined) {
          toast.success("File has been uploaded successfully");
          dispatch(getAllUsers()).then(() => {
            props?.handleFileUploadModalClose();
          });
        } else {
          // Handle case where response does not indicate success
          toast.error("Failed to upload file.");
        }
      })
      .catch((err) => {
        console.error("An error occurred:", err);
        toast.error("An error occurred during file upload.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Container>
              <Row className="align-items-center">
                <Col md="12" className="m-auto">
                  <div className="shadow-box p-0">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="login-form mt-0">
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <div
                                {...getRootProps({ className: "dropzone" })}
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  component="label"
                                  variant="contained"
                                  htmlFor="account-settings-upload-image"
                                  className="upload-modal-btn"
                                >
                                  <BsCloudUpload />
                                  <span>Drag & Drop Files here</span>
                                  <span>or</span>
                                  <span className="browse-btn">
                                    Browse Files
                                  </span>
                                  {/* <input hidden type='file' accept='image/png, image/jpeg' id='account-settings-upload-image' /> */}
                                  <input
                                    {...getInputProps()}
                                    type="file"
                                    hidden
                                  />
                                </Button>
                              </div>
                              {errors.file && (
                                <Form.Text className="text-danger">
                                  {errors.file.message}
                                </Form.Text>
                              )}
                            </Row>

                            <Grid
                              container
                              spacing={6}
                              sx={{ marginTop: "15px" }}
                            >
                              {acceptedFileItems?.length ? (
                                <Grid item sm={12}>
                                  <Alert
                                    severity="success"
                                    sx={{ width: "100%" }}
                                  >
                                    <AlertTitle>Accepted files</AlertTitle>
                                    {acceptedFileItems}
                                  </Alert>
                                </Grid>
                              ) : null}
                              {fileRejectionItems?.length ? (
                                <Grid item sm={12}>
                                  <Alert
                                    severity="error"
                                    sx={{ width: "100%" }}
                                  >
                                    <AlertTitle>Rejected files</AlertTitle>
                                    {fileRejectionItems}
                                  </Alert>
                                </Grid>
                              ) : null}
                            </Grid>
                            <Row className="justify-content-center">
                              <Col md="6">
                                <Button
                                  type="submit"
                                  className="common-button w-100 mt-3  m-0"
                                >
                                  Submit
                                </Button>
                              </Col>
                              <Col md="12">
                                <p className="para-modal d-md-flex align-items-center justify-content-center gap-2">
                                  Note:The uploaded CSV file should be similar
                                  as the{" "}
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDownload(e);
                                    }}
                                    className="common-button"
                                  >
                                    Sample CSV
                                  </Button>
                                </p>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading || pageLoading} />
    </section>
  );
};

export default SuperAdminImportUsersForm;
