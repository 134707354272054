import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../configs/config";

const useAuth = () => {
  const encryptedToken = localStorage.getItem("accessToken");
  if (encryptedToken === null) {
    return {
      userName: "",
      role: [],
      isSuperAdmin: false,
      isUser: false,
      status: "",
    };
  }

  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  let isWorkspaceAdmin = false;
  let isUser = false;
  let isSuperAdmin = false;
  let status = "USER";

  if (token) {
    const data = localStorage.getItem("user");
    if (data === null) {
      return {
        userName: "",
        role: [],
        isSuperAdmin,
        isUser,
        status,
      };
    } else {
      const decryptData = CryptoJS.AES.decrypt(
        data,
        REACT_APP_CRYPTOJS_SECRETKEY,
        {
          REACT_APP_CRYPTOJS_VECTOR,
        }
      ).toString(CryptoJS.enc.Utf8);
      const { userName, role } = JSON.parse(decryptData);

      isWorkspaceAdmin = role?.includes("COMPANYADMIN");
      isUser = role?.includes("USER");
      isSuperAdmin = role?.includes("SUPERADMIN");

      if (isWorkspaceAdmin) status = "COMPANYADMIN";
      if (isUser) status = "USER";
      if (isSuperAdmin) status = "SUPERADMIN";

      return {
        userName,
        role,
        isSuperAdmin,
        isUser,
        status,
      };
    }
  } else {
    return {
      userName: "",
      role: [],
      isSuperAdmin,
      isUser,
      status,
    };
  }
};

export default useAuth;
