import React, { useState, useEffect } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container } from "react-bootstrap";
import { Box, Tooltip, Typography } from "@mui/material";
import ModalComponent from "../../components/layout/shared/ModalComponent";
import ModalWrapper from "../../components/layout/shared/ModalWrapper";
import SuperAdminAddUpn from "./SuperAdminAddUpn";
import SuperAdminEditUpn from "./SuperAdminEditUpn";
import { GoPasskeyFill } from "react-icons/go";
import { getCompanyList } from "../../redux/slice/companySlice";
import { getUpnNamesByCompanyId } from "../../redux/slice/upnSlice";
import Select from "react-select";
import { Control, colourStylesState } from "../../configs/stylesConfig";
import Spinner from "../../components/layout/shared/Spinner";
import { DELETEUPNDATA } from "../../services/upnService.js";
import { toast } from "react-toastify";

const PowerBiUpnList = (props) => {
  const upnListFromReducer = useSelector(
    ({ upn }) => upn.userPrincipalNameList
  );
  const upnLoading = useSelector(({ upn }) => upn.upnLoading);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showEditUpnModal, setShowEditUpnModal] = useState(false);
  const [upnId, setUpnId] = useState("");

  const [showAddUpnModal, setShowAddUpnModal] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);

  const handleAddUpnModalShow = () => {
    setShowAddUpnModal(true);
  };

  const handleAddUpnModalClose = () => {
    setShowAddUpnModal(false);
  };

  const handleEditUpnModalShow = (upnId) => {
    setUpnId(upnId);
    setShowEditUpnModal(true);
  };

  const handleEditUpnModalClose = (upnId) => {
    setShowEditUpnModal(false);
  };

  const deleteUpnHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const deletedResponse = await DELETEUPNDATA(upnId);

      if (deletedResponse?.data?.acknowledged) {
        success = true;
        dispatch(getUpnNamesByCompanyId(selectedCompany?.id));
      }
    } catch (error) {
      // Handle the error here, for example, log the error message or show a notification to the user.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);

      if (success) {
        toast.success("UPN has been removed successfully");
      }
    }
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (stId) => {
    setShowDeleteModal(false);
  };

  const rowDeleteHandler = (upn_Id) => {
    setUpnId(upn_Id);
    handleDeleteModalShow(upnId);
  };

  const rowEditHandler = (upnId) => {
    handleEditUpnModalShow(upnId);
  };

  const [collection, setCollection] = useState();

  useEffect(() => {
    if (upnListFromReducer?.length) {
      setCollection(upnListFromReducer);
    } else if (upnListFromReducer?.length === 0) {
      setCollection([]);
    }
  }, [upnListFromReducer]);

  useEffect(() => {
    setCompanyLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (companyListFromReducer?.length) {
      setCompanyOptions(companyListFromReducer);
    }
  }, [dispatch, companyListFromReducer]);

  useEffect(() => {
    if (
      selectedCompany?.id !== undefined &&
      selectedCompany?.id !== "" &&
      companyListFromReducer?.length
    ) {
      setLoading(true);

      dispatch(getUpnNamesByCompanyId(selectedCompany?.id))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [selectedCompany]);

  const columns = [
    {
      accessorKey: "user_principal_name",
      header: "User Principal Name (UPN)",
      Cell: ({ cell }) => {
        const upnValue = cell.getValue() || "-N/A-";
        return (
          <Tooltip title={upnValue}>
            <span>{upnValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => {
        const upn = row.original;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "24px",
              padding: "4px",
            }}
          >
            {/* Remove UPN Button */}
            <Tooltip title="Remove UPN">
              <Button
                style={{ backgroundColor: "transparent" }}
                onClick={() => rowDeleteHandler(upn?._id)}
              >
                <i
                  style={{ color: "#3a49a1" }}
                  className="fa fa-times"
                  aria-hidden="true"
                ></i>
              </Button>
            </Tooltip>

            {/* Edit UPN Button */}
            <Tooltip
              title={selectedCompany?.id ? "Edit UPN" : "Select Company first"}
            >
              <Button
                style={{ backgroundColor: "transparent" }}
                disabled={!selectedCompany?.id}
                onClick={() => rowEditHandler(upn?._id)}
              >
                <img
                  src="/assets/images/editIcon.svg"
                  className="img-fluid"
                  alt="icon"
                />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: collection || [], // Assuming 'collection' is your data source
    enableDensityToggle: false,
    enableSorting: false,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: false,
    enableHiding: false,
    initialState: {
      density: "compact",
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 16.2rem)",
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #3a4981",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
    },
    muiSearchTextFieldProps: {
      // label: `Filter by ${column.columnDef.header}`,
      sx: {
        backgroundColor: "#f6f6f6",
      },
    },
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#F6f6f6",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "rgba(65, 213, 213, 0.1)",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          padding: "0.5rem",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ minWidth: "288px" }}>
            <Select
              className=""
              options={companyOptions}
              onChange={(e) => {
                setSelectedCompany(e);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={" "}
              disabled={companyLoading}
              value={selectedCompany}
              styles={colourStylesState}
              isClearable={false}
              label={companyLoading ? "Loading options" : "Company"}
              components={{
                Control: Control,
                // DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <MRT_GlobalFilterTextField table={table} />
        </Box>

        <div
          lg="2"
          md={2}
          className="new-message-btn d-flex gap-2 justify-content-end"
        >
          <Tooltip
            title={selectedCompany?.id ? "Add UPN" : "Select Company first"}
          >
            <Button
              className="new-message"
              onClick={selectedCompany?.id && handleAddUpnModalShow}
            >
              <GoPasskeyFill />
            </Button>
          </Tooltip>
        </div>
      </Box>
    ),
  });

  return (
    <>
      <Container className="p-0 " style={{ maxWidth: "100%" }}>
        <MaterialReactTable table={table} />
      </Container>

      <ModalComponent
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-trash" aria-hidden="true"></i>
          <h4>Remove</h4>
          <p>Are you sure you want to Remove this UPN ?</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button className="red-back" onClick={deleteUpnHandler}>
              Remove
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalWrapper
        showModal={showAddUpnModal}
        handleClose={handleAddUpnModalClose}
        title="Add UPN"
        keyboard={true}
        size="lg"
      >
        <SuperAdminAddUpn
          handleAddUpnModalClose={handleAddUpnModalClose}
          company={selectedCompany}
        />
      </ModalWrapper>
      <ModalWrapper
        keyboard={true}
        showModal={showEditUpnModal}
        handleClose={handleEditUpnModalClose}
        title={"Update UPN"}
        size="lg"
      >
        <SuperAdminEditUpn
          company={selectedCompany}
          upnId={upnId}
          handleEditUpnModalClose={handleEditUpnModalClose}
        />
      </ModalWrapper>

      <Spinner
        isLoading={loading || upnLoading || companyLoading || reportLoading}
      />
    </>
  );
};
export default PowerBiUpnList;
