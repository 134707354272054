import { toggleLoading } from "../redux/slice/loadingSlice";

export const loadingFalse = (dispatch) => {
  dispatch(toggleLoading(false));
};

export const firstCapitalLetter = (name) => {
  return name?.[0].slice("").toUpperCase() + name?.slice(1).toLowerCase();
};

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function limitString(str, maxLength = 10) {
  if (!str) {
    return "-N/A-";
  }
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}
