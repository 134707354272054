import React, { useState, useEffect, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container } from "react-bootstrap";
import { Box, Tooltip } from "@mui/material";
import ModalComponent from "../../components/layout/shared/ModalComponent";
import ModalWrapper from "../../components/layout/shared/ModalWrapper";
import SuperAdminAddJobTitle from "./SuperAdminAddJobTitle";
import PaginationEllipsis from "../../components/layout/shared/PaginationEllipsis";
import { getCompanyList } from "../../redux/slice/companySlice";
import Select from "react-select";
import { Control, colourStylesState } from "../../configs/stylesConfig";
import Spinner from "../../components/layout/shared/Spinner";

import { toast } from "react-toastify";
import { FaUsersCog } from "react-icons/fa";
import SuperAdminEditJobTitle from "./SuperAdminEditJobTitle.jsx";
import { getJobTitlesByCompanyId } from "../../redux/slice/jobTitleSlice.js";
import { DELETEJOBTITLEDATA } from "../../services/jobTitleService.js";
import { throttle } from "lodash";

const JobTitleListScreen = (props) => {
  const listRef = useRef();
  const jobTitleListFromReducer = useSelector(
    ({ jobTitle }) => jobTitle.jobTitlesList
  );
  const jobTitleLoading = useSelector(
    ({ jobTitle }) => jobTitle.jobTitleLoading
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );

  listRef.current = jobTitleListFromReducer?.length && jobTitleListFromReducer;

  const tableHead = {
    job_title: "Job Title",
    user_principal: "User Principal Name",
    reports: "Reports",
    action: "Actions",
  };

  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const maxVisiblePages = 5;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showEditJobTitleModal, setShowEditJobTitleModal] = useState(false);
  const [jobTitleId, setJobTitleId] = useState("");
  const [collection, setCollection] = useState();

  const [showAddJobTitleModal, setShowAddJobTitleModal] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(jobTitleListFromReducer.length / countPerPage);
    const items = [];

    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <li
          key={i}
          className={
            i === currentPage
              ? "rc-pagination-item rc-pagination-item-active"
              : "rc-pagination-item"
          }
          onClick={() => updatePage(i)}
        >
          {i}
        </li>
      );
    }

    if (totalPages > maxVisiblePages) {
      if (currentPage > maxVisiblePages - 2) {
        items.splice(1, 0, <PaginationEllipsis key="ellipsis" />);
      }
      if (currentPage < totalPages - 2) {
        items.splice(-1, 0, <PaginationEllipsis key="ellipsis" />);
      }
    }

    return items;
  };

  const handleAddJobTitleModalShow = () => {
    setShowAddJobTitleModal(true);
  };

  const handleAddJobTitleModalClose = () => {
    setShowAddJobTitleModal(false);
  };

  const handleEditJobTitleModalShow = (jobTitleId) => {
    setJobTitleId(jobTitleId);
    setShowEditJobTitleModal(true);
  };

  const handleEditJobTitleModalClose = (jobTitleId) => {
    setShowEditJobTitleModal(false);
  };

  const deleteJobTitleHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const deletedResponse = await DELETEJOBTITLEDATA(jobTitleId);

      if (deletedResponse?.status === 200) {
        success = true;
        dispatch(getJobTitlesByCompanyId(selectedCompany?.id));
      }
    } catch (error) {
      // Handle the error here, for example, log the error message or show a notification to the user.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);

      if (success) {
        toast.success("Job Title has been removed successfully");
      }
    }
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (stId) => {
    setShowDeleteModal(false);
  };

  const rowDeleteHandler = (jobTitleId) => {
    setJobTitleId(jobTitleId);
    handleDeleteModalShow(jobTitleId);
  };

  const rowEditHandler = (jobTitleId) => {
    handleEditJobTitleModalShow(jobTitleId);
  };

  useEffect(() => {
    if (jobTitleListFromReducer?.length) {
      setCollection(jobTitleListFromReducer);
    } else if (jobTitleListFromReducer?.length === 0) {
      setCollection([]);
    }
  }, [jobTitleListFromReducer]);

  const searchData = useRef(
    throttle((val) => {
      const query = val?.trim()?.toLowerCase();
      setCurrentPage(1);

      if (listRef?.current?.length) {
        const data = listRef.current
          .filter((item) => {
            const jobTitle = item?.job_title?.toLowerCase() || "";
            return jobTitle.indexOf(query) > -1;
          })
          .slice(0, countPerPage);

        setCollection(data);
      } else {
        setCollection([]);
      }
    }, 300)
  );

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(jobTitleListFromReducer?.slice(from, to));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <th key={index}>{title}</th>
    ));
  };

  useEffect(() => {
    setCompanyLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (companyListFromReducer?.length) {
      setCompanyOptions(companyListFromReducer);
    }
  }, [dispatch, companyListFromReducer]);

  useEffect(() => {
    if (
      selectedCompany?.id !== undefined &&
      selectedCompany?.id !== "" &&
      companyListFromReducer?.length
    ) {
      setLoading(true);

      dispatch(getJobTitlesByCompanyId(selectedCompany?.id))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [selectedCompany]);

  const columns = [
    {
      accessorKey: "job_title",
      header: "Job Title",
      Cell: ({ cell }) => {
        const jobTitle = cell.getValue() || "-N/A-";
        return (
          <Tooltip title={jobTitle}>
            <span>{jobTitle}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "user_principal",
      header: "User Principal",
      Cell: ({ row }) => {
        const userPrincipals = row.original.user_principal || [];
        const displayValue =
          userPrincipals.length > 2
            ? userPrincipals.slice(0, 2).join(", ") + ", ..."
            : userPrincipals.length
            ? userPrincipals.join(", ")
            : "-N/A-";
        const tooltipValue = userPrincipals.length
          ? userPrincipals.join(", ")
          : "-N/A-";
        return (
          <Tooltip title={tooltipValue}>
            <span>{displayValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "reports",
      header: "Reports",
      Cell: ({ row }) => {
        const reports = row.original.reports || [];
        const displayValue =
          reports.length > 2
            ? reports.slice(0, 2).join(", ") + ", ..."
            : reports.length
            ? reports.join(", ")
            : "-N/A-";
        const tooltipValue = reports.length ? reports.join(", ") : "-N/A-";
        return (
          <Tooltip title={tooltipValue}>
            <span>{displayValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => {
        const jobTitle = row.original;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "24px",
              padding: "4px",
            }}
          >
            {/* Remove Job Title Button */}
            <Tooltip title="Remove Job Title">
              <Button
                style={{ backgroundColor: "transparent" }}
                onClick={() => rowDeleteHandler(jobTitle?._id)}
              >
                <i
                  style={{ color: "#3a49a1" }}
                  className="fa fa-times"
                  aria-hidden="true"
                ></i>
              </Button>
            </Tooltip>

            {/* Edit Job Title Button */}
            <Tooltip
              title={
                selectedCompany?.id ? "Edit Job Title" : "Select Company first"
              }
            >
              <Button
                style={{ backgroundColor: "transparent" }}
                disabled={!selectedCompany?.id}
                onClick={() => rowEditHandler(jobTitle?._id)}
              >
                <img
                  src="/assets/images/editIcon.svg"
                  className="img-fluid"
                  alt="icon"
                />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  const table = useMaterialReactTable({
    columns,
    data: collection || [], // Assuming 'collection' is your data source
    enableDensityToggle: false,
    enableSorting: false,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: false,
    enableHiding: false,
    initialState: {
      density: "compact",
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 16.2rem)",
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #3a4981",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
    },
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#F6f6f6",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "rgba(65, 213, 213, 0.1)",
        },
      },
    },
    muiSearchTextFieldProps: {
      // label: `Filter by ${column.columnDef.header}`,
      sx: {
        backgroundColor: "#f6f6f6",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    renderTopToolbar: ({ table }) => (
      <Box
        className="searchContainer"
        sx={{
          display: "flex",
          flexGrow: 1,
          gap: "16px",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div style={{ minWidth: "288px" }}>
            <Select
              className=""
              options={companyOptions}
              onChange={(e) => {
                setSelectedCompany(e);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={" "}
              disabled={companyLoading}
              value={selectedCompany}
              styles={colourStylesState}
              isClearable={false}
              label={companyLoading ? "Loading options" : "Company"}
              components={{
                Control: Control,
                // DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <MRT_GlobalFilterTextField table={table} />
        </Box>

        <div className="new-message-btn d-flex gap-2 justify-content-end">
          <Tooltip
            title={
              selectedCompany?.id ? "Add Job Title" : "Select Company first"
            }
          >
            <Button
              className="new-message"
              onClick={selectedCompany?.id && handleAddJobTitleModalShow}
            >
              <FaUsersCog />
            </Button>
          </Tooltip>
        </div>
      </Box>
    ),
  });

  return (
    <>
      <Container className="p-0 " style={{ maxWidth: "100%" }}>
        <MaterialReactTable table={table} />
      </Container>

      <ModalComponent
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-trash" aria-hidden="true"></i>
          <h4>Remove</h4>
          <p>Are you sure you want to Remove this Job Title ?</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button className="red-back" onClick={deleteJobTitleHandler}>
              Remove
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalWrapper
        showModal={showAddJobTitleModal}
        handleClose={handleAddJobTitleModalClose}
        title="Add Job Title"
        keyboard={true}
        size="lg"
      >
        <SuperAdminAddJobTitle
          handleAddJobTitleModalClose={handleAddJobTitleModalClose}
          company={selectedCompany}
        />
      </ModalWrapper>
      <ModalWrapper
        keyboard={true}
        showModal={showEditJobTitleModal}
        handleClose={handleEditJobTitleModalClose}
        title={"Update Job Title"}
        size="lg"
      >
        <SuperAdminEditJobTitle
          company={selectedCompany}
          jobTitleId={jobTitleId}
          handleEditJobTitleModalClose={handleEditJobTitleModalClose}
        />
      </ModalWrapper>

      <Spinner
        isLoading={
          loading || jobTitleLoading || companyLoading || reportLoading
        }
      />
    </>
  );
};
export default JobTitleListScreen;
