import { toast } from "react-toastify";
import { axiosInstance } from "./axiosInstance";

export const GETALLUPN = (groupId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/userprincipal/userprincipals`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const GETUPNBYCOMPANYID = (companyId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`userprincipal/getByCompanyId/${companyId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const POSTADDUPN = (formData) =>
  new Promise((resolve, reject) => {
    return axiosInstance
      .post("/userprincipal/userprincipal", formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });

export const GETUPNBYID = (upnId) =>
  new Promise((resolve, reject) =>
    axiosInstance
      .get(`/userprincipal/userprincipal/${upnId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.status_message || "Something Went Wrong"
        );
        reject(error.response?.data);
      })
  );

export const PUTUPNDATA = (params) =>
  new Promise((resolve, reject) => {
    //  debugger
    return axiosInstance
      .put(`/userprincipal/userprincipal`, params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });

export const DELETEUPNDATA = (upnId) =>
  new Promise((resolve, reject) => {
    return axiosInstance
      .delete(
        `/userprincipal/userprincipal/${upnId}
`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error(error?.message && "Please enter correct credentials");
        reject(error?.message);
      });
  });
