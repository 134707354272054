import React, { useEffect, useState } from "react";
import { Navbar, Container, Col, Row, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { logout, userLogout } from "../../redux/slice/authSlice";

import { BsPerson } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import { firstCapitalLetter } from "../../utils/commonFunction";
import { RiLockPasswordLine } from "react-icons/ri";

export default function DashHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  const logoutHandler = () => {
    dispatch(userLogout(loggedInUser?._id)).then((res) => {
      navigate("/", { replace: true });
    });
  };

  return (
    <>
      <header className="main-header login-header-home">
        <Navbar expand="lg" sticky="top">
          <Container fluid>
            <Row className="w-100 m-auto">
              <Col>
                <div className="d-flex align-items-center">
                  <Navbar.Brand className="d-block p-0">
                    <Link to="/user/dashboard">
                      <img
                        src={"/assets/images/white_logo.png"}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </Navbar.Brand>
                  <div className="d-flex align-items-center justify-content-end gap-2 w-100">
                    <Dropdown className="dropdown-notify">
                      <Dropdown.Toggle id="dropdown-basic">
                        <div>
                          <div
                            style={{
                              display: "inline-block",
                              borderRadius: "50%",
                              border: "2px solid #3a4981",
                              height: "30px",
                              width: "30px",
                              marginRight: "5px",
                              backgroundColor: "#3a4981",
                              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                              backdropFilter: "blur(2px)",
                            }}
                          >
                            {loggedInUser?.firstName
                              ? loggedInUser?.firstName
                                  ?.charAt(0)
                                  .toUpperCase() +
                                loggedInUser?.lastName?.charAt(0).toUpperCase()
                              : loggedInUser?.given_name
                                  ?.charAt(0)
                                  .toUpperCase() +
                                loggedInUser?.family_name
                                  ?.charAt(0)
                                  .toUpperCase()}
                          </div>
                          {firstCapitalLetter(
                            loggedInUser?.firstName
                              ? loggedInUser?.firstName
                              : loggedInUser?.given_name
                          ) +
                            " " +
                            firstCapitalLetter(
                              loggedInUser?.lastName
                                ? loggedInUser?.lastName
                                : loggedInUser?.family_name
                            )}
                        </div>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="d-block p-0">
                          <Link to="/user/profile">
                            <BsPerson />
                            My Profile
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="d-block p-0">
                          <Link
                            to={`/user/change-password/${loggedInUser?._id}`}
                          >
                            <RiLockPasswordLine />
                            Change Password
                          </Link>
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={logoutHandler}
                          className="d-block p-0"
                        >
                          <Link to="/login">
                            <MdOutlineLogout />
                            Logout
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    </>
  );
}
