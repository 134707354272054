import React from "react";
import { Link, useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="notFoundContainer">
      <div className="childContainer">
        <h1>Oops!</h1>
        <p>404-page not found!</p>

        <Link to="/" className="btn btn-primary btn-lg">
          Click! To redirect back to home
        </Link>
        <p
          onClick={() => {
            navigate(-1);
          }}
          className="btn btn-primary btn-lg"
        >
          Click! To go back to previous page
        </p>
      </div>
    </div>
  );
}

export default NotFound;
